
.accordion-container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .accordion-item {
    border-bottom: 1px solid var(--color-body);
    margin: 10px 0;
    border-radius: 4px;
  }
  .your-class-name1x2121 {
    font-size: 50px;
    font-family: "Oswald", sans-serif;
    font-weight: 500;
  }
  .elementor-image-box-description323232{
    width: 90%;
  }
  .elementor-image-box-D1213SD{
    margin-left:20px;
  }
  .accordion-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    padding: 15px;
    background-color: transparent;
    font-size: 20px;
    text-align: left;

  }
  
  .accordion-content {
    padding: 15px;
    background-color: transparent;
    text-align: left;


  }

  .accoinds{
    font-weight: 200;
    font-size: 17px;
  }
  
  .accordion-icon {
    margin-right: 10px;
  }
  
  
    .elementor-element { 
      --flex-direction: initial; 
      --flex-wrap: initial; 
      --justify-content: initial; 
      --align-items: initial; 
      --align-content: initial; 
      --gap: initial; 
      --flex-basis: initial; 
      --flex-grow: initial; 
      --flex-shrink: initial; 
      --order: initial; 
      --align-self: initial; 
      flex-basis: var(--flex-basis); 
      flex-grow: var(--flex-grow); 
      flex-shrink: var(--flex-shrink); 
      order: var(--order); 
      align-self: var(--align-self);
    } 
  
    .elementor-element:where(.e-con-full, .elementor-widget) { 
      flex-direction: var(--flex-direction); 
      flex-wrap: var(--flex-wrap); 
      justify-content: var(--justify-content); 
      align-items: var(--align-items); 
      align-content: var(--align-content); 
      gap: var(--gap);
    } 
  
    .e-con { 
      --border-radius: 0; 
      --border-top-width: 0px; 
      --border-right-width: 0px; 
      --border-bottom-width: 0px; 
      --border-left-width: 0px; 
      --border-style: initial; 
      --border-color: initial; 
      --container-widget-flex-grow: 0; 
      --content-width: min(100%,var(--container-max-width,1140px)); 
      --width: 100%; 
      --min-height: initial; 
      --height: auto; 
      --text-align: initial; 
      --margin-top: 0px; 
      --margin-right: 0px; 
      --margin-bottom: 0px; 
      --margin-left: 0px; 
      --padding-top: var(--container-default-padding-top,10px); 
      --padding-right: var(--container-default-padding-right,10px); 
      --padding-bottom: var(--container-default-padding-bottom,10px); 
      --padding-left: var(--container-default-padding-left,10px); 
      --position: relative; 
      --z-index: revert; 
      --overflow: visible; 
      --gap: var(--widgets-spacing,20px); 
      --overlay-mix-blend-mode: initial; 
      --overlay-opacity: 1; 
      --overlay-transition: 0.3s; 
      position: var(--position); 
      width: var(--width); 
      min-width: 0; 
      min-height: var(--min-height); 
      height: var(--height); 
      border-radius: var(--border-radius); 
      z-index: var(--z-index); 
      overflow: var(--overflow); 
      transition: background var(--background-transition,.3s),border var(--border-transition,.3s),box-shadow var(--border-transition,.3s),transform var(--e-con-transform-transition-duration,.4s); 
      margin-block-start: var(--margin-block-start); 
      margin-inline-end: var(--margin-inline-end); 
      margin-block-end: var(--margin-block-end); 
      margin-inline-start: var(--margin-inline-start); 
      padding-inline-start: var(--padding-inline-start); 
      padding-inline-end: var(--padding-inline-end); 
      --margin-block-start: var(--margin-top); 
      --margin-block-end: var(--margin-bottom); 
      --margin-inline-start: var(--margin-left); 
      --margin-inline-end: var(--margin-right); 
      --padding-inline-start: var(--padding-left); 
      --padding-inline-end: var(--padding-right); 
      --padding-block-start: var(--padding-top); 
      --padding-block-end: var(--padding-bottom); 
      --border-block-start-width: var(--border-top-width); 
      --border-block-end-width: var(--border-bottom-width); 
      --border-inline-start-width: var(--border-left-width); 
      --border-inline-end-width: var(--border-right-width);
    } 
  
    .e-con-full { 
      text-align: var(--text-align); 
      padding-block-start: var(--padding-block-start); 
      padding-block-end: var(--padding-block-end);
    } 
  
    .e-con { 
      display: var(--display);
    } 
  
    .e-con { 
      --container-max-width: 1340px; 
      --container-default-padding-top: 0px; 
      --container-default-padding-right: 0px; 
      --container-default-padding-bottom: 0px; 
      --container-default-padding-left: 0px;
    } 
  
    .elementor-element { 
      --widgets-spacing: 30px 30px;
    } 


  
    :is(.elementor-section-wrap, [data-elementor-id]) > .e-con  { 
      --margin-left: auto; 
      --margin-right: auto; 
      max-width: min(100%,var(--width));
    } 
  
    .elementor-element.elementor-element-f694779  { 
      --display: flex; 
      --flex-direction: row; 
      --container-widget-flex-grow: 1; 
      --gap: 0px 0px; 
      --background-transition: 0.3s; 
      --padding-top: 0em; 
      --padding-bottom: 0em; 
      --padding-left: 0em; 
      --padding-right: 0em;
    } 
  
    *,:after,:before { 
      box-sizing: border-box;
    } 
  
    * , .elementor ::after, .elementor ::before { 
      box-sizing: border-box;
    } 
  
    .e-con:before, .e-con > .elementor-background-slideshow::before, .e-con > .elementor-motion-effects-container > .elementor-motion-effects-layer::before, :is(.e-con, .e-con > .e-con-inner) > .elementor-background-video-container::before { 
      content: var(--background-overlay); 
      display: block; 
      position: absolute; 
      mix-blend-mode: var(--overlay-mix-blend-mode); 
      opacity: var(--overlay-opacity); 
      transition: var(--overlay-transition,.3s); 
      border-radius: var(--border-radius); 
      border-style: var(--border-style); 
      border-color: var(--border-color); 
      border-block-start-width: var(--border-block-start-width); 
      border-inline-end-width: var(--border-inline-end-width); 
      border-block-end-width: var(--border-block-end-width); 
      border-inline-start-width: var(--border-inline-start-width); 
      top: calc(0px - var(--border-top-width)); 
      left: calc(0px - var(--border-left-width)); 
      width: max(100% + var(--border-left-width) + var(--border-right-width),100%); 
      height: max(100% + var(--border-top-width) + var(--border-bottom-width),100%);
    } 
  
    .e-con:before { 
      transition: background var(--overlay-transition,.3s),border-radius var(--border-transition,.3s),opacity var(--overlay-transition,.3s);
    } 
  
    .elementor *, .elementor ::after, .elementor ::before { 
      box-sizing: border-box;
    } 
  
    .elementor-element.elementor-element-030709c  { 
      --display: flex; 
      --background-transition: 0.3s; 
      --padding-top: 7em; 
      --padding-bottom: 7em; 
      --padding-left: 16.5em; 
      --padding-right: 7em;
    } 

  
  @media (min-width: 768px){ 
    .elementor-element.elementor-element-030709c  { 
      --width: 50%;
    } 
  }     
  
  @media all{ 
    .e-con::before, .e-con > .elementor-background-slideshow::before, .e-con > .elementor-motion-effects-container > .elementor-motion-effects-layer::before, :is(.e-con, .e-con > .e-con-inner) > .elementor-background-video-container::before { 
      content: var(--background-overlay); 
      display: block; 
      position: absolute; 
      mix-blend-mode: var(--overlay-mix-blend-mode); 
      opacity: var(--overlay-opacity); 
      transition: var(--overlay-transition,.3s); 
      border-radius: var(--border-radius); 
      border-style: var(--border-style); 
      border-color: var(--border-color); 
      border-block-start-width: var(--border-block-start-width); 
      border-inline-end-width: var(--border-inline-end-width); 
      border-block-end-width: var(--border-block-end-width); 
      border-inline-start-width: var(--border-inline-start-width); 
      top: calc(0px - var(--border-top-width)); 
      left: calc(0px - var(--border-left-width)); 
      width: max(100% + var(--border-left-width) + var(--border-right-width),100%); 
      height: max(100% + var(--border-top-width) + var(--border-bottom-width),100%);
    } 
  
    .elementor-element.elementor-element-0752ef4  { 
      --display: flex; 
      --align-items: flex-end; 
      --container-widget-width: calc( ( 1 - var( --container-widget-flex-grow ) ) * 100% ); 
      --background-transition: 0.3s;
    } 
  
    .elementor-element.elementor-element-0752ef4  { 
      --border-transition: 0.3s;
    } 
  }     
  
  @media (min-width: 768px){ 
    .elementor-element.elementor-element-0752ef4  { 
      --width: 50%;
    } 
  }     
  
  @media all{ 
    .elementor-element.elementor-element-0752ef4:not(.elementor-motion-effects-element-type-background)  { 
      background-image: url("https://gtkit.rometheme.pro/gordon/wp-content/uploads/sites/42/2023/12/Why-Choose-Us-Image-2.jpg"); 
      background-position: center center; 
      background-size: cover;
    } 
  
    .elementor-element.elementor-element-0752ef4 , .elementor-5 .elementor-element.elementor-element-0752ef4::before { 
      --border-transition: 0.3s;
    } 
  
    .elementor-widget { 
      position: relative;
    } 
  
    .elementor-widget:not(:last-child) { 
      margin-bottom: 20px;
    } 
  
    .e-con .elementor-widget  { 
      min-width: 0;
    } 
  
    .elementor-widget:not(:last-child) { 
      margin-block-end: 30px;
    } 
  
    .e-con .elementor-widget.elementor-widget  { 
      margin-block-end: 0;
    } 
  
    .e-con > .elementor-widget  { 
      max-width: 100%;
    } 
  
    .animated { 
      animation-duration: 1.25s;
    } 
  
    .fadeInUp { 
      animation-name: fadeInUp;
    } 
  
    .animated.animated-slow { 
      animation-duration: 2s;
    } 
  
    .elementor-element.elementor-element-ea76868  { 
      z-index: 1;
    } 
  
    .elementor-5 .elementor-element.elementor-element-0752ef4, .elementor-5 .elementor-element.elementor-element-0752ef4::before { 
      --border-transition: 0.3s;
    } 
  
    .elementor-element.elementor-element-c78750b  { 
      --display: flex; 
      --gap: 20px 0px; 
      --background-transition: 0.3s; 
      --overlay-opacity: 0.7; 
      --padding-top: 2em; 
      --padding-bottom: 2em; 
      --padding-left: 2em; 
      --padding-right: 2em;
    } 
  }     
  
  @media (min-width: 768px){ 
    .elementor-element.elementor-element-c78750b  { 
      --width: 55%;
    } 
  }     
  
  @media all{ 
    .elementor-5 .elementor-element.elementor-element-c78750b::before, .elementor-5 .elementor-element.elementor-element-c78750b > .elementor-background-video-container::before, .elementor-5 .elementor-element.elementor-element-c78750b > .e-con-inner > .elementor-background-video-container::before, .elementor-5 .elementor-element.elementor-element-c78750b > .elementor-background-slideshow::before, .elementor-5 .elementor-element.elementor-element-c78750b > .e-con-inner > .elementor-background-slideshow::before, .elementor-5 .elementor-element.elementor-element-c78750b > .elementor-motion-effects-container > .elementor-motion-effects-layer::before { 
      --background-overlay: ''; 
      background-color: transparent; 
      background-image: linear-gradient(90deg, var( --e-global-color-secondary ) 0%, var( --e-global-color-6578e3d ) 100%);
    } 
  
    .elementor-element .elementor-widget-container  { 
      transition: background .3s,border .3s,border-radius .3s,box-shadow .3s,transform var(--e-transform-transition-duration,.4s);
    } 
  
    .e-con > .elementor-widget > .elementor-widget-container  { 
      height: 100%;
    } 
  

  
    .elementor-heading-title { 
      padding: 0; 
      margin: 0; 
      line-height: 1;
    } 
  
  
    h3 { 
      margin-block-start: .5rem; 
      margin-block-end: 1rem; 
      font-weight: 500; 
      line-height: 1.2; 
      color: inherit;
    } 
  
    h3 { 
      font-size: 1.75rem;
    } 
  
    .elementor-kit-3 h3 { 
      color: var( --e-global-color-primary ); 
      font-size: var( --e-global-typography-97ddfd7-font-size ); 
      font-weight: var( --e-global-typography-97ddfd7-font-weight ); 
      line-height: var( --e-global-typography-97ddfd7-line-height ); 
      letter-spacing: var( --e-global-typography-97ddfd7-letter-spacing ); 
      word-spacing: var( --e-global-typography-97ddfd7-word-spacing );
    } 
  
    .elementor-element.elementor-element-ff95ac7 > .elementor-widget-container  { 
      margin: 0px 0px -10px 0px;
    } 
  
    .ekit-wid-con div  { 
      -webkit-tap-highlight-color: transparent;
    } 
  
    .ekit-wid-con .elementskit-accordion  { 
      counter-reset: myNumber;
    } 
  

  
    .elementor-widget-image-box .elementor-image-box-wrapper  { 
      text-align: center;
    } 
  }     
  
  @media (min-width: 768px){ 
    .elementor-widget-image-box.elementor-position-left .elementor-image-box-wrapper  { 
      display: flex;
    } 
  
    .elementor-widget-image-box.elementor-position-left .elementor-image-box-wrapper  { 
      text-align: start; 
      flex-direction: row;
    } 
  
    .elementor-widget-image-box.elementor-vertical-align-bottom .elementor-image-box-wrapper  { 
      align-items: flex-end;
    } 
  }     
  
  @media all{ 
    .elementor-element.elementor-element-507675d .elementor-image-box-wrapper  { 
      text-align: left;
    } 
  
    .ekit-wid-con .elementskit-accordion .elementskit-card  { 
      border: 0; 
      border-radius: 0; 
      background-color: transparent; 
      -webkit-transition: all .4s ease; 
      transition: all .4s ease;
    } 
  
    .ekit-wid-con .elementskit-accordion .elementskit-card:not(:last-child)  { 
      margin-bottom: 10px;
    } 
  
    .elementor-element.elementor-element-2e7e3c4 .elementskit-accordion > .elementskit-card  { 
      border-style: solid; 
      border-width: 0px 0px 1px 0px; 
      border-color: var( --e-global-color-primary );
    } 
  
    .elementor-element.elementor-element-2e7e3c4 .elementskit-accordion .elementskit-card:not(:last-child)  { 
      margin-bottom: 20px;
    } 
  
    .elementor-element.elementor-element-2e7e3c4 .elementskit-accordion > .elementskit-card.active  { 
      border-style: solid; 
      border-width: 0px 0px 1px 0px; 
      border-color: var( --e-global-color-primary );
    } 
  
    .elementor-element.elementor-element-2e7e3c4 .ekit-wid-con > .elementskit-accordion > .elementskit-card:last-child  { 
      border: 0px;
    } 
  
    .elementor-widget-image-box .elementor-image-box-img  { 
      display: inline-block;
    } 
  
    .elementor-widget:not(.elementor-widget-text-editor):not(.elementor-widget-theme-post-content) figure  { 
      margin: 0;
    } 
  
    .elementor-element.elementor-element-507675d.elementor-position-left .elementor-image-box-img  { 
      margin-right: 20px;
    } 
  
    .elementor-element.elementor-element-507675d .elementor-image-box-wrapper .elementor-image-box-img  { 
      width: 15%;
    } 
  
    .elementor-widget-image-box .elementor-image-box-content  { 
      width: 100%;
    } 
  
    .ekit-wid-con .elementskit-accordion .elementskit-card-header  { 
      padding: 0; 
      background-color: transparent; 
      border-bottom: 0 solid #000;
    } 
  
    .ekit-wid-con .collapse  { 
      display: none;
    } 
  
    .ekit-wid-con .show  { 
      display: block!important;
    } 
  

  
    .elementor img { 
      height: auto; 
      max-width: 100%; 
      border: none; 
      border-radius: 0; 
      box-shadow: none;
    } 
  
    .elementor-element.elementor-element-507675d .elementor-image-box-img img  { 
      border-radius: 100px; 
      transition-duration: 0.3s;
    } 
  
    h4 { 
      margin-block-start: .5rem; 
      margin-block-end: 1rem; 
      font-family: inherit; 
      font-weight: 500; 
      line-height: 1.2; 
      color: inherit;
    } 
  
    h4 { 
      font-size: 1.5rem;
    } 
  
 
  
    .elementor-element.elementor-element-507675d .elementor-image-box-title  { 
      margin-bottom: 5px;
    } 
  
    .elementor-widget-image-box .elementor-image-box-description  { 
      margin: 0;
    } 
  

  

    .ekit-accordion--toggler { 
      font-size: 16px; 
      line-height: 24px;
    } 
  
    .elementor a { 
      box-shadow: none; 
      text-decoration: none;
    } 
  
    .elementor-kit-3 a { 
      color: var( --e-global-color-6578e3d );
    } 
  
    .ekit-wid-con a  { 
      -webkit-tap-highlight-color: transparent;
    } 
  
    .ekit-wid-con a  { 
      text-decoration: none; 
      outline: 0;
    } 
  
    .elementor-widget-container .ekit-wid-con a  { 
      text-decoration: none;
    } 
  
    .ekit-wid-con .elementskit-accordion .elementskit-card-header > .elementskit-btn-link  { 
      display: -webkit-box; 
      display: -ms-flexbox; 
      display: flex; 
      color: #000; 
      background-color: #f7f9fb; 
      font-weight: 700; 
      padding: 14px 30px; 
      border: 1px solid transparent; 
      -webkit-box-align: center; 
      -ms-flex-align: center; 
      align-items: center; 
      -webkit-transition: all .4s ease; 
      transition: all .4s ease; 
      position: relative;
    } 
  

    .elementor-element.elementor-element-2e7e3c4 .elementskit-accordion .elementskit-card .elementskit-card-header > .elementskit-btn-link[aria-expanded="true"]  { 
      color: var( --e-global-color-primary );
    } 
  
    .elementor-element.elementor-element-2e7e3c4 .elementskit-accordion.accoedion-primary .elementskit-card .elementskit-card-header > .elementskit-btn-link[aria-expanded="true"] ,.elementor-element.elementor-element-2e7e3c4 .elementskit-accordion .elementskit-card-header > .elementskit-btn-link[aria-expanded="true"]  { 
      background-color: var( --e-global-color-e1dec1a );
    } 
  

    .elementor-kit-3 a:hover { 
      color: var( --e-global-color-accent );
    } 
  
    .ekit-wid-con a:active { 
      -moz-outline: none; 
      outline: 0;
    } 
  
    .ekit-wid-con a:active, .ekit-wid-con a:hover { 
      text-decoration: none; 
      outline: 0;
    } 
  
     .ekit-wid-con .elementskit-accordion .elementskit-card-header > .elementskit-btn-link:hover { 
      text-decoration: none;
    } 
  
    .ekit-accordion--content { 
      font-size: 16px; 
      line-height: 24px;
    } 
  
    .ekit-wid-con .elementskit-accordion .elementskit-card-body  { 
      padding: 20px 30px;
    } 
  
    .ekit-wid-con .elementskit-accordion .elementskit-card-body  { 
      padding-top: 30px; 
      padding-bottom: 30px; 
      padding-left: 65px; 
      padding-right: 85px;
    } 
  
    .elementor-element.elementor-element-2e7e3c4 .elementskit-accordion .elementskit-card-body  { 
      color: var( --e-global-color-primary ); 
      padding: 0px 0px 20px 0px;
    } 

    .elementor-element.elementor-element-2e7e3c4 .elementskit-accordion .elementskit-card-body  { 
      background-color: var( --e-global-color-e1dec1a );
    } 
  
    .ekit-wid-con a:active, .ekit-wid-con a:focus, .ekit-wid-con input, .ekit-wid-con input:active, .ekit-wid-con input:focus, .ekit-wid-con input:hover, .ekit-wid-con textarea, .ekit-wid-con textarea:active, .ekit-wid-con textarea:focus, .ekit-wid-con textarea:hover { 
      -moz-outline: none; 
      outline: 0;
    } 
  
    .ekit-wid-con a:active, .ekit-wid-con a:focus, .ekit-wid-con a:hover, .ekit-wid-con a:visited { 
      text-decoration: none; 
      outline: 0;
    } 
  
    .ekit-wid-con .elementskit-accordion .elementskit-card-header > .elementskit-btn-link:focus, .ekit-wid-con .elementskit-accordion .elementskit-card-header > .elementskit-btn-link:hover { 
      text-decoration: none;
    } 
  
    .elementor-element.elementor-element-2e7e3c4 .ekit_accordion_icon_left_group  { 
      margin: 0px -10px 0px 0px;
    } 
  
    .ekit-wid-con .elementskit-accordion .elementskit-card-header > .elementskit-btn-link .ekit_accordion_icon_left_group  { 
      padding-right: 26px; 
      float: left;
    } 
  
    .ekit-wid-con .elementskit-accordion .ekit-accordion-title  { 
      padding-right: 10px; 
      display: inline-block;
    } 
  
    .ekit-wid-con p  { 
      margin-bottom: 10px;
    } 
  
    .ekit-wid-con .elementskit-accordion .elementskit-card-body p  { 
      margin-top: 0; 
      margin-bottom: 1em;
    } 
  
    .ekit-wid-con .elementskit-accordion .elementskit-card-body p:last-child  { 
      margin-bottom: 0;
    } 
  
    .elementor-element.elementor-element-2e7e3c4 .elementskit-accordion .elementskit-card-body p  { 
      color: var( --e-global-color-primary );
    } 
  
  
  
    .ekit-wid-con .elementskit-accordion .elementskit-card-header > .elementskit-btn-link[aria-expanded="true"] .ekit_accordion_normal_icon  { 
      display: none;
    } 
  
    .ekit-wid-con .elementskit-accordion .elementskit-card-header > .elementskit-btn-link[aria-expanded="true"] .ekit_accordion_active_icon  { 
      display: block;
    } 
  
    .ekit-wid-con .elementskit-accordion .elementskit-card-header > .elementskit-btn-link[aria-expanded="false"] .ekit_accordion_normal_icon  { 
      display: block;
    } 
  
    .ekit-wid-con .elementskit-accordion .elementskit-card-header > .elementskit-btn-link[aria-expanded="false"] .ekit_accordion_active_icon  { 
      display: none;
    } 
  
    .ekit-wid-con .icon ,.icon { 
      speak: none; 
      font-style: normal; 
      font-weight: 400; 
      font-variant: normal; 
      text-transform: none; 
      line-height: 1; 
      -webkit-font-smoothing: antialiased; 
      -moz-osx-font-smoothing: grayscale;
    } 
  
    .ekit-wid-con .icon-left  { 
      float: left;
    } 
  
    .ekit-wid-con .elementskit-accordion .elementskit-card-header > .elementskit-btn-link[aria-expanded="true"] .icon-open  { 
      display: none;
    } 
  
    .elementor-element.elementor-element-2e7e3c4 .elementskit-accordion .elementskit-card-header .elementskit-btn-link .icon-open  { 
      font-size: 20px;
    } 
  
    .elementor-element.elementor-element-2e7e3c4 .elementskit-accordion .elementskit-card-header .elementskit-btn-link .icon-open  { 
      color: var( --e-global-color-6578e3d );
    } 
  
    .ekit-wid-con .fasicon,.ekit-wid-con .icon , .ekit-wid-con .icon::before,.fasicon,.icon,.icon:before { 
      speak: none; 
      font-style: normal; 
      font-weight: 400; 
      font-variant: normal; 
      text-transform: none; 
      line-height: 1; 
      -webkit-font-smoothing: antialiased; 
      -moz-osx-font-smoothing: grayscale;
    } 
  
    .ekit-wid-con .fasicon.icon-chevron-down-circle::before, .ekit-wid-con .icon.icon-chevron-down-circle::before,.icon.icon-chevron-down-circle:before { 
      content: "\e888";
    } 
  
    .ekit-wid-con .fasicon, .ekit-wid-con .icon, .ekit-wid-con .icon::before, .fasicon, .icon, .icon::before { 
      speak: none; 
      font-style: normal; 
      font-weight: 400; 
      font-variant: normal; 
      text-transform: none; 
      line-height: 1; 
      -webkit-font-smoothing: antialiased; 
      -moz-osx-font-smoothing: grayscale;
    } 
  
    .ekit-wid-con .elementskit-accordion .elementskit-card-header > .elementskit-btn-link[aria-expanded="true"] .icon-closed  { 
      display: block;
    } 
  
    .elementor-element.elementor-element-2e7e3c4 .elementskit-accordion .elementskit-card-header .elementskit-btn-link .icon-closed  { 
      color: var( --e-global-color-6578e3d );
    } 
  
    .elementor-element.elementor-element-2e7e3c4 .elementskit-accordion .elementskit-card .elementskit-card-header .elementskit-btn-link .icon-closed  { 
      font-size: 20px;
    } 
  
    .ekit-wid-con .fasicon.icon-chevron-up-circle::before, .ekit-wid-con .icon.icon-chevron-up-circle::before,.icon.icon-chevron-up-circle:before { 
      content: "\e887";
    } 
  
    .ekit-wid-con .elementskit-accordion .elementskit-card-header > .elementskit-btn-link[aria-expanded="false"] .icon-open  { 
      display: block;
    } 
  
    .ekit-wid-con .fasicon.icon-chevron-down-circle::before, .ekit-wid-con .icon.icon-chevron-down-circle::before, .icon.icon-chevron-down-circle::before { 
      content: "\e888";
    } 
  
    .ekit-wid-con .elementskit-accordion .elementskit-card-header > .elementskit-btn-link[aria-expanded="false"] .icon-closed  { 
      display: none;
    } 
  
    .ekit-wid-con .fasicon.icon-chevron-up-circle::before, .ekit-wid-con .icon.icon-chevron-up-circle::before, .icon.icon-chevron-up-circle::before { 
      content: "\e887";
    } 
  }     
  
  .raxcd{
    height: 800px;
  }
  @keyframes fadeInUp { 
    0% {  
        opacity: 0; 
        transform: translate3d(0,100%,0); 
        opacity: 0; 
        transform: translate3d(0px, 100%, 0px); 
    }  
    100% {  
        opacity: 1; 
        transform: none; 
        opacity: 1; 
        transform: none; 
    }  
  
  } 


  @media (max-width: 1000px) {
 
    .elementor-element.elementor-element-f694779 {
      --display: flex;
      --flex-direction: column-reverse;
      --container-widget-flex-grow: 1;
      --gap: 0px 0px;
      --background-transition: 0.3s;
      --padding-top: 0em;
      --padding-bottom: 0em;
      --padding-left: 0em;
      --padding-right: 0em;
  }
  .elementor-element.elementor-element-0752ef4:not(.elementor-motion-effects-element-type-background) {
    background-image: url(https://gtkit.rometheme.pro/gordon/wp-content/uploads/sites/42/2023/12/Why-Choose-Us-Image-2.jpg);
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 400px;
}
.accordion-container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}
.elementor-element.elementor-element-030709c  { 
  --display: block; 
  --background-transition: 0.3s; 
  --padding-top: 0; 
  --padding-bottom: 0; 
  --padding-left: 0; 
  --padding-right: 0;
} 
.e-con.e-flex {
  /* --flex-basis: auto; */
  /* --flex-grow: 0; */
  /* --flex-shrink: 1; */
  /* flex: var(--flex-grow) var(--flex-shrink) var(--flex-basis); */
}
.elementor-element.elementor-element-030709c  { 
  --width: 100%;
} 
}


@media (max-width: 768px) {
 

.elementor-element.elementor-element-0752ef4:not(.elementor-motion-effects-element-type-background) {
  background-image: url(https://gtkit.rometheme.pro/gordon/wp-content/uploads/sites/42/2023/12/Why-Choose-Us-Image-2.jpg);
  background-position: center center;
  background-size: cover;
  width: 100%;
  height:300px;
}

}

