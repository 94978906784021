@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;800&display=swap');


@import "src/assets/scss/default/_variables.scss";

.stripeimage {
  width: 139px;
  height: 696px;
  position: absolute;
  top: -60%;
  left: 15%;
  z-index: 9999;
  object-fit: cover;
  opacity: 0.6;
  animation: fade 4s ease-in-out infinite;
}
.wirraxx {
  padding: 10px;
  background-color: var(--color-primary-light222);
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 80px;
  color:white;

}
.card-containermining {
  display: flex;
  flex-wrap: wrap; /* Ekle */
  justify-content: center; /* Kartlar arasındaki mesafe */
  gap: 40px; /* Kartlar arasındaki boşluk */
}

.cardmining {
  background-image: url('../../../assets/images/Service-1.jpg'); /* Arka plan resmi URL'si */
  background-size: cover; /* Resmin tamamen kapsamasını sağlar */
  background-position: center; /* Resmi ortalar */
  background-repeat: no-repeat; /* Resmin tekrar etmesini engeller */
  width: 600px; /* Kart genişliği 400px */
  height: 400px; /* Kart yüksekliği 400px */
  padding: 20px; /* Kart içeriği için boşluk */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Hafif gölge */
  border-radius: 8px; /* Kart köşelerini yuvarla */
  box-sizing: border-box; /* Padding hesaba katılması için */
  flex: 0 1 auto; /* Kartların esnememesi ve sabit kalması için */
  overflow: hidden;
 
}

.cardmining2 {
  background-image: url('../../../assets/images/Service-2.jpg'); /* Arka plan resmi URL'si */
  width: 600px; /* Kart genişliği 400px */
  height: 400px; /* Kart yüksekliği 400px */
  background-color: white; /* Kart arka plan rengi */
  padding: 20px; /* Kart içeriği için boşluk */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Hafif gölge */
  border-radius: 8px; /* Kart köşelerini yuvarla */
  box-sizing: border-box; /* Padding hesaba katılması için */
  flex: 0 1 auto; /* Kartların esnememesi ve sabit kalması için */
  overflow: hidden;

}
.text-section2{
  flex-direction: column;
  justify-content: center;
  position: absolute;
  right: -12%;
  width: 86%;
  z-index: 10;
}
.card-headermining {
  margin-top: 265px; /* Başlangıçta margin-top 150px */
  transition: all 0.3s ease; /* Hover geçiş süresi */
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 10px;
  padding-right:10px;
}
.card-headermining2 {
  margin-top: 235px; /* Başlangıçta margin-top 150px */
  transition: all 0.3s ease; /* Hover geçiş süresi */
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 10px;
  padding-right:10px;
}
.cardmining:hover .card-headermining {
  margin-top: 100px; /* Başlangıçta margin-top 150px */

}
  .cardmining2:hover .card-headermining2 {
    margin-top: 50px; /* Başlangıçta margin-top 150px */
}

.aboabo {
  width: 100%;
  min-height: 300px;
  background-image: 
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), 
    url('../../images/Landing-Image-2.jpg'); /* Resim yolunu buraya ekleyin */
background-color: #cccccc; /* Used if the image is unavailable */
height: 300px; /* You must set a specified height */
background-position: center; /* Center the image */
background-repeat: no-repeat; /* Do not repeat the image */
background-size: cover; /* Resize the background image to cover the entire container */
display: flex;
justify-content: flex-start;
align-items: center;
padding-left: 15%;
margin-top: 20px;
}

.backmadenciservo{
  background-color: transparent;
    background-image: none;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    
}
.backmadenciservoinner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: initial;
  align-content: initial;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  align-self: auto;
  gap: 50px; /* Itemler arasına 15px mesafe ekler */
}

.capcap{
  margin: 0;
  padding: 15px 70px;
  
  outline: none;
  border: none;
  border-radius: 5px;
  background: lighten(crimson, 10%);
  color: white;
  overflow: hidden;
  transition: 500ms ease all;

  &:before {
    content: '';
    position: absolute;
    top: -40%;
    right: 110%;
    width: 30px;
    height: 200%;
    background: rgba(white, .3);
    transform: rotate(20deg);
  }
  
  &:hover {
    background: lighten(crimson, 13%);

    &:before {
      right: -50%;
      transition: 1s ease all;
    }
  }

}
#earth-canvas{
  height: 100%;
  width: 100%;
  background: #010826;
  position: relative;
  z-index: 22;
}
.ewewsd12133xz{
  font-size: 20px;
  font-weight: 700;
  color: var(--color-primary);
  letter-spacing: 2px;
}
#html2canvas {
  position: absolute;
  z-index: -1;
  left: 0;
  top:0;
  background: rgba(0, 0, 0, 0);
}
.css3d-wapper {
  pointer-events: none;
  color: #fff;
}
.rn-newsletter-default{
  position: relative;
}
.css3d-wapper .fire-div {
  font-size: 50px;
  border-top: 3px solid #0cd1eb;
  font-family: var(--font-tt-lakes-neue-extended-demibold);
  background: rgba(40, 108, 181, 0.5);
  padding: 5px;
  padding-left: 15px;
  padding-right: 20px;

}
.buttonnewhomefinance {
  position: absolute;
  top:60%;
  right: -20%;
  border: none;
  padding: 10px 20px;
  font-size: 30px;
  background: transparent;
  font-family: var(--font-tt-lakes-neue-extended-regular);
  color: #ffa500;
  text-transform: uppercase;
  border: 3px solid #ffa500;
  cursor: pointer;
  transition: all 0.7s;
  overflow: hidden;
  border-radius: 0;
}
#loading{
  position:absolute;
  left:0;
  top:0;
  right:0;
  bottom: 0;
  width: 100vw;
  height:100vh;
  z-index: 999;
  background:#010826;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color:#409EFF;
  font-size: 15px;
  letter-spacing: 2px;
  overflow: hidden;
}
@keyframes zoomOut {
  0%{
    opacity:1
  }
  50%{
    opacity:0;
    transform:scale3d(1.3,1.3,1.3)
  }
  to{
    opacity:0
  }
}
#loading.out{
  animation:zoomOut 0.5s  linear forwards;
  pointer-events: none;
}
#loading.out .sk-chase-dot,
#loading.out .sk-chase{
  animation: null;
}
.sk-chase {
  margin-bottom: 20px;
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2s infinite ease-in-out both;
}

.sk-chase-dot::before {
  content: '';
  display: block;
  width: 20%;
  height: 20%;
  background-color: #409EFF;
  border-radius: 100%;
  animation: sk-chase-dot-before 2s infinite ease-in-out both;
}
.sk-chase-dot:nth-child(1) {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2) {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s;
}
.sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2):before {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}

.sk-chase-dot .sk-chase-dot:nth-child(2) {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s;
}
.sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2):before {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}

@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}
.buttonnewhomefinance:hover {
  color: #000;
}
.buttonnewhomefinance > span {
  transition: all 0.7s;
  z-index: -1;
}

.buttonnewhomefinance .first {
  content: "";
  position: absolute;
  right: 100%;
  top: 0;
  width: 25%;
  height: 100%;
  background: #ffa500;
}

.buttonnewhomefinance:hover .first {
  top: 0;
  right: 0;
}
.buttonnewhomefinance .second {
  content: "";
  position: absolute;
  left: 25%;
  top: -100%;
  height: 100%;
  width: 25%;
  background: #ffa500;
}

.buttonnewhomefinance:hover .second {
  top: 0;
  left: 50%;
}

.buttonnewhomefinance .third {
  content: "";
  position: absolute;
  left: 50%;
  height: 100%;
  top: 100%;
  width: 25%;
  background: #ffa500;
}

.buttonnewhomefinance:hover .third {
  top: 0;
  left: 25%;
}

.buttonnewhomefinance .fourth {
  content: "";
  position: absolute;
  left: 100%;
  top: 0;
  height: 100%;
  width: 25%;
  background: #ffa500;
}

.buttonnewhomefinance:hover .fourth {
  top: 0;
  left: 0;
}

.outtext2{
  position: absolute;
  top: 40%;
  right:-20%;
  width: 40%;
  font-size:80px;
  line-height:70px;
  font-family: var(--font-tt-lakes-neue-compressed-demibold);
  z-index: -2;
  color: white;
}
.brraa{
  width: 100%;
  height: 92vh;
  margin-left: -450px;
}
.outtext{
  position: absolute;
  top:20%;
  right: -20%;
  width: 40%;
  font-size: 110px;
  opacity: .4;
  line-height:110px;
  font-family: var(--font-tt-lakes-neue-extended-black);
  pointer-events: none;
  z-index: -2;
  color: var(--color-dark);
  filter: blur(2px);
  text-shadow: -1px -1px 0 var(--color-primary), 1px -1px 0 var(--color-primary), -1px 1px 0 var(--color-primary), 1px 1px 0 var(--color-primary);
}
.imgplane{
  width: 1000px;
  height: 1000px;
  object-fit: contain;
  margin-top: -380px;
  margin-left: 600px;


}
.indexes,
.tabs {
  list-style-type: none;



}

.indexes {
  font-size: 2rem; /* İki katına çıkardık */

  & li {
    padding: 2rem; /* İki katına çıkardık */
    border: 2px solid transparent; /* İki katına çıkardık */
    cursor: pointer;
  }
}

.tabs {
  position: relative;
}

.tab {
  position: absolute;
  display: flex;
  width: 1060px; /* İki katına çıkardık */
  height: auto; /* İki katına çıkardık */
  opacity: 0;
  background-color: black;
  overflow: hidden;
}

.moon {
  width: 1200px; /* İki katına çıkardık */
  height: 600px; /* İki katına çıkardık */
  font: 1.4rem impact, sans-serif; /* İki katına çıkardık */
  margin-bottom: 20px;

margin-top: 40px;
  & header {
    font-size: 2.4rem; /* İki katına çıkardık */
    text-transform: uppercase;
    color: white;

    & span {
      color: var(--primary);
    }
  }

  & section {
    display: flex;
    gap: 4rem; /* İki katına çıkardık */
  }
}
.btnnn {
  
  color: #fff;
  cursor: pointer;
  // display: block;
  font-size:16px;
  font-weight: 400;
  line-height: 45px;
  padding: 0 0 2em;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;


  
  @media(min-width: 600px) {
      
    padding: 0 1em 2em;
  

  }
  
  &:hover { text-decoration: none; 
  }
  
}



.tab-content svg {
  height: auto; /* SVG'nin yüksekliğinin orantılı olmasını sağlar */
}

.btnnn-1 {
  background:transparent;
  font-weight: 100;
  color: var(--color-body);
  svg {
    height: 45px;
    left: 0;
    position: absolute;
    top: 0; 
    width: 163px;

  }
  
  rect {
    fill: none;
    stroke: #fff;
    stroke-width: 2;
    stroke-dasharray: 422, 0;
    transition: all 0.35s linear;
  }
}

.btnnn-1:hover {
  color: var(--color-body);
  rect {
    stroke-width: 5;
    stroke-dasharray: 15, 310;
    stroke-dashoffset: 48;
    transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
  }
}
.btnnn-1 a{
  width: auto;
  margin-left: 10px;
}
.btnnn-1 a:hover{
  color: var(--color-body);
}


.tab-content {
  position: relative;
  z-index: 10; /* İki katına çıkardık */
  width: 600px; /* İki katına çıkardık */
  display: flex;
  flex-direction: column;
  gap: 1rem; /* İki katına çıkardık */
  opacity: 0;
  transform: translateY(-10rem); /* İki katına çıkardık */

  & h3 {
    font-weight: 900;
    font-size: 2rem; /* İki katına çıkardık */
    border-bottom: 3px solid white; /* İki katına çıkardık */
    padding-bottom: 2rem; /* İki katına çıkardık */
  }

  & p {
    font-weight: 300;
    line-height: 2; /* İki katına çıkardık */
    color: rgba(255, 255, 255, 0.7);


  }

  & button {
    width: fit-content;
    background-color: transparent;
    color: white;
    border: 2px solid white; /* İki katına çıkardık */
    font-size: 1.4rem; /* İki katına çıkardık */
    padding: 1.5rem 2rem; /* İki katına çıkardık */
    cursor: pointer;
  }
}

@keyframes content {
  100% {
    opacity: 1;
    transform: translateY(0);

  }
}

.tab-image {
  position: absolute;
  right: 2rem; /* İki katına çıkardık */
  width: 400px; /* İki katına çıkardık */
  height: 400px; /* İki katına çıkardık */
  opacity: 0;
  transform: translateX(4rem); /* İki katına çıkardık */

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background-color: var(--overlay);
    mix-blend-mode: multiply;
  }

  & img {
    width: inherit;
    height: inherit;
    object-fit: cover;
    object-position: left;

  }
}

@keyframes image {
  100% {
    opacity: 1;
    width: 600px; /* İki katına çıkardık */
    transform: translateX(0);
  }
}

.active .tab {
  opacity: 1;
  z-index: 10; /* İki katına çıkardık */
}

.active .tab-content {
  animation: content 0.9s ease-out 0.4s forwards;
}

.active .tab-image {
  animation: image 1s ease-out forwards;
}

@keyframes fade {
  0%, 100% {
    opacity: 0.6;
    content: url('http://localhost:3000/images/bg/strip5.png');
  }
  50% {
    opacity: 0.6;
    content: url('http://localhost:3000/images/bg/strip6.png');
  }
}

.barr-container {
  display: flex;
  align-items: center;
  height: 670px;
  position: absolute;
  left: 12%;
  top: -22%;
}

/* Ekran genişliği 1600px'in altında olduğunda elementi gizle */
@media (max-width: 1600px) {
  .barr-container {
    z-index: -2;
  }
}
@media (max-width: 971px) {
  .barr-container {
    display: none;
  }
}
.barr, .barr2 {
  width: 9px;
  margin-left: 7px;
  height: 400px;
  background: linear-gradient(to bottom, rgb(0, 195, 255) 35%, rgb(0, 195, 255) 55%, rgb(0, 195, 255), rgb(0, 195, 255), rgb(0, 195, 255), #140101);

}

.barr {
  animation: moveUpDown 3s infinite ease-in-out;
}

.barr2 {
  animation: downup 3s infinite ease-in-out;
}

@keyframes downup {
  0%, 20%, 100% {
    transform: translateY(-25px);
  }
  40%, 60% {
    transform: translateY(0);
  }
  80% {
    transform: translateY(-25px);
  }
}

@keyframes moveUpDown {
  0%, 20%, 100% {
    transform: translateY(0);
  }
  40%, 60% {
    transform: translateY(-25px);
  }
  80% {
    transform: translateY(0);
  }
}


.headingthird{
  font-size: 65px;
  font-weight: 300;
  margin-top: 140px;
}
.workdivx{
  width: 19%;
  height: 40%;
  position: absolute;
  left: 29%;
  top: 35%;
  background-color: transparent;
  background-image: linear-gradient(180deg, #F2295B00 0%, var(--color-primary) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  padding-top: 30px;
}
.unordcontainer {
    width: 100%;

    height: auto;
    margin-top: -8px;
    position: relative;
    background:transparent;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    padding: 5px;
    padding-top: 100px;
}
.services-list-container{
    float: right;

}
.services-listtt {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
    position: relative;
    padding: 15px;


}


.service-carddd {
    background-color:black;

    text-align: center;
    transition: 0.3s;
    height: 220px;
    width: 330px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Kart içeriğini dikeyde ortalamak için kullanıldı */
    align-items: center; /* Kart içeriğini yatayda ortalamak için kullanıldı */
    position: relative; /* Görsel açıdan çapraz hat çizmek için kullanıldı */


}




.service-carddd:hover {
}

.service-titleee {
    font-size: 16px;
    color:var(--color-body);

}

.service-descriptionnn {
    font-size: 12px;
    color: black;

}

.card {
    width: 100%; /* Adjust as needed */
    height: 400px;
    background: transparent;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    display: inline-block;
  }
  
  .card_left,
  .card_middle,
  .card_right {
    height: 100%;
    float: left;
    overflow: hidden;
  }
  
  .card_left {
    width: 30%;
    background: transparent;
  
    &__button {
      width: 100%;
      padding: 20px;
    }
  }
  
  .card_middle {
    width: 35%;
    background: transparent;
    border-color: var(--color-body);
    border: 1px solid;
    border-right: none;


    img {
      width: 100%;
      height: 400px;
      object-fit: cover;
      object-position: top;
    }
  }
  
  .card_right {
    width: 35%;
    float: left;
    background: #000000;
    height: 100%;
    border-color: var(--color-body);

    border: 1px solid;


  
    h1 {
      color: var(--color-body);
      font-family: 'Jost', sans-serif;
      font-weight: 600;
      text-align: left;
      font-size: 40px;
      margin: 30px 0 0 0;
      padding: 0 0 0 40px;
      letter-spacing: 1px;
    }
    &__details {
      ul {
        list-style-type: none;
        padding: 0 0 0 40px;
        margin: 10px 0 0 0;
        
        li {
          display: inline;
          color: var(--color-body);
          font-family: 'Jost', sans-serif;
          font-weight: 400;
          font-size: 14px;
          padding: 0 40px 0 0;
        }
      }
    }
  
    &__rating {
      &__stars {
        position: relative;
        right: 160px;
        margin: 10px 0 10px 0;
  
        fieldset, label {
          margin: 0;
          padding: 0;
        }
  
        .rating {
          border: none;
        }
  
        .rating > input {
          display: none;
        }
  
        .rating > label:before {
          margin: 5px;
          font-size: 1.25em;
          display: inline-block;
          content: "\f005";
          font-family: FontAwesome;
        }
  
        .rating > .half:before {
          content: "\f089";
          position: absolute;
        }
  
        .rating > label {
          color: #ddd;
          float: right;
        }
  
        /***** CSS Magic to Highlight Stars on Hover *****/
  
        .rating > input:checked ~ label,
        .rating:not(:checked) > label:hover,
        .rating:not(:checked) > label:hover ~ label {
          color: red;
        }
  
        .rating > input:checked + label:hover,
        .rating > input:checked ~ label:hover,
        .rating > label:hover ~ input:checked ~ label,
        .rating > input:checked ~ label:hover ~ label {
          color: red;
        }
      }
    }
  
    &__review {
      p {
        color: var(--color-body);        
        font-family: 'Jost', sans-serif;
        width: 80%;
        word-wrap: break-word;
        font-size: 13px;
        padding: 0 40px 0 40px;
        letter-spacing: 1px;
        margin: 10px 0 10px 0;
        line-height: 20px;
      }
  
      a {
        text-decoration: none;
        font-family: 'Jost', sans-serif;
        font-size: 14px;
        padding: 0 0 0 40px;
        color: red;
        margin: 0;
      }
    }
  
    &__button {
      a {
        color: red;
        text-decoration: none;
        font-family: 'Montserrat', sans-serif;
        border: 2px solid red;
        padding: 10px 10px 10px 40px;
        font-size: 12px;
        background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/343086/COMdoWZ.png);
        background-size: 12px 12px;
        background-repeat: no-repeat;
        background-position: 7% 50%;
        border-radius: 5px;
        -webkit-transition-property: all;
        transition-property: all;
        -webkit-transition-duration: .5s;
        transition-duration: .5s;
      }
  
      a:hover {
        color: #000000;
        background-color: red;
        background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/343086/rFQ5dHA.png);
        background-size: 12px 12px;
        background-repeat: no-repeat;
        background-position: 10% 50%;
        cursor: pointer;
        -webkit-transition-property: all;
        transition-property: all;
        -webkit-transition-duration: .5s;
        transition-duration: .5s;
      }
  
      padding: 0 0 0 40px;
      margin: 30px 0 0 0;
    }
  }
.bambo{
  text-align: left
}
.spariya{

}
.vixad{

}
.dixad{

}
.padi{

}
.zra{
  display: flex;
  justify-content: flex-end;
}
.gapbeka{
  padding-top: 70px;
}
.infinite-scroll {
  margin-right: 20px; /* Öğenin içine sağdan 10px boşluk ekler */
  box-sizing: border-box; /* padding'i genişliğe dahil etmek için */
}
.infinite-scroll::-webkit-scrollbar {
  width: 10px; /* Kaydırma çubuğunun genişliği */
  margin-right: 10px;
}

/* Özel sınıf için kaydırma çubuğunun içindeki kaydırıcı (thumb) kısmını özelleştirir */
.infinite-scroll::-webkit-scrollbar-thumb {
  background-color: red; /* Kaydırıcı rengi */
  height: 10px;
}

/* Özel sınıf için kaydırma çubuğunun arka planını (track) özelleştirir */
.infinite-scroll::-webkit-scrollbar-track {
  
background-color: rgba(140,0,0,0.2); /* Çubuğun arka plan rengi */
}
.vertical-bar {

  width: 5px; /* Çubuğun genişliği */
  height: 300px; /* Çubuğun yüksekliği */
  background-color: rgba(140,0,0,0.2); /* Çubuğun arka plan rengi */
  position: relative;
  overflow: hidden; /* Kare çubuğun dışına çıktığında gizlenir */
  margin-right: 50px;
  margin-top: 40px;

}

.moving-square {
  
  width: 20px; /* Karenin genişliği */
  height: 20px; /* Karenin yüksekliği */
  background-color: red; /* Karenin rengi */
  box-shadow: 0 0 15px 5px rgba(255, 0, 0, 0.7); 

  position: absolute;
  bottom: 0; /* Başlangıç pozisyonu */
  animation: moveUpDownn 12s linear infinite; /* Animasyon */
}

@keyframes moveUpDownn {
  0%, 100% {
    bottom: 0;
  }
  50% {
    bottom: 280px; /* Çubuğun yüksekliğine göre ayarlayın */
  }
}
.dribba{
  display: flex;
}
.fikar{
  color: red;
  font-size: 28px;
  position: absolute;
  top: -60px;
}

.language-dropdown {
  background-color: black;
  color: white;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 5px;
  -webkit-appearance: none; /* Tarayıcı özel stillerini kaldırır */
  -moz-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  background-position: right 10px center;
}
.rart{

}
.xxxweee{
  width: 550px;
  margin-left: -25px;
}


    @media (max-width: 576px) {
      .dribba{
        display: grid;
      }
      .rart{
          width: 100%;
      }
    .moon {
      display: grid; /* Flex yerine block yapısı kullan */
      grid-template-rows: auto 1fr; /* İndeksler üstte, sekmeler altta olacak şekilde ayarlar */
      width: 100%;
      height: auto;
      margin-top: 0;
  
    }

    .xxxweee{
      width: 430px;
      
    }
    .moon section{
      display: grid; /* Flex yerine block yapısı kullan */
      grid-template-rows: auto 1fr; /* İndeksler üstte, sekmeler altta olacak şekilde ayarlar */
      gap: 0px;
    }
    .tab-content{
      width: 350px;
      height: auto;
    }
    .padi{
      margin-right:5px;
    }
    .zra{
      display: none;
    }
    .vixad{
  width:80% !important; 
    }
    .dixad{
      width:18% !important; 

    }
    
    .spariya{
      margin-left: 16px;
    }
    .active .tab-content{
  
      width: 350px;
      height: auto;
  
    }
    .bambo{
      text-align: center;
    }

    .css3d-wapper {
      pointer-events: none;
      color: #fff;
    }
    .rn-newsletter-default{
      position: relative;
    }
    .css3d-wapper .fire-div {
      font-size: 50px;
      border-top: 3px solid #0cd1eb;
      font-family: var(--font-tt-lakes-neue-extended-demibold);
      background: rgba(40, 108, 181, 0.5);
      padding: 5px;
      padding-left: 15px;
      padding-right: 20px;
    
    }
    .tab{
      width: 100%;
      height: auto;
    }
    .active .tab-image img {
      opacity: 0.4;
    }
    .active .tab-content p {
      color:rgba(255, 255, 255, 0.9);
      background: rgba(0,0,0,0.2);
      height: 410px;
      overflow: hidden;
    }
    .tab-image {
      left: 0;
    }
    .sec{
     
    }
    .indexes {
      display: flex;
      margin-left: 5px;
      margin-right: 5px;
      margin-bottom: 20px; 
    }
    .indexes li {
      width: 90px;
    }
  
    .tabs {
      /* Sekmelerin altta sıralanması için ayarlar */
    }
  }
  .layout-container {
    display: flex;
    height: 80vh; /* Yüksekliği tam ekran yapar */
  }
  .layout-containersec23 {
    display: flex;
    height: auto; /* Yüksekliği tam ekran yapar */
  }
  
  .text-section {
    flex: 4; /* %40 genişlik */
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    position: relative;
    background-color: transparent;
  }
  .text-sectionsec23{
    flex: 4; /* %40 genişlik */
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    position: relative;
    background-color: transparent;
  }
  .text-section3{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    text-align: left;
    padding-left: 8%;
  }
.imgone121{
  position: absolute;
  top: 30px;
  width: 60%;
  height: 200px;
  right:80px;
  object-fit: cover;
  object-position: center;
}
.imgone221{
  position: absolute;
  top: 200px;
  width: 50%;
  height: 60%;
  right:"-5%";
  object-fit: cover;
  object-position: center;
}
  .innertextsection2{
    line-height: 130px;
    font-size: 120px;
    font-weight: 500;
    width: 80%;
    letter-spacing: -5px;
    color: var(--color-body);
  }
  .anasayfayukarıtitle{
    font-size: 20px;
    font-weight: 700;
    color: var(--color-primary);
  }
  .innertextsectionmining2{
    line-height: 100px;
    font-size: 60px;
    font-weight: 500;
    color: var(--color-body);
  }
  .innerservicesection2{
    line-height: 50px;
    font-size: 50px;
    font-weight: 700;
    color: var(--color-body);
    width: 70%;
  }
  .innertextsection3{
    font-size: 25px;
    font-weight: 200;
    width: 75%;
  }
  .innertextsectionmining3{
    font-size: 20px;
    font-weight: 200;
    width:40%;
  }
  .innerservicesection3{
    font-size: 18px;
    font-weight: 200;
    width: 75%;
  }
  .büybüy{
    width: 50%;
  }
  .video-section {
    flex: 6; /* %60 genişlik */
    position: relative;
  }
  .video-section2x3ana{
    flex: 6; /* %60 genişlik */
    position: relative;
  }
  .full-screen-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid transparent;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;


  }
  .titxsb4311{
    font-size: 25px;
    font-weight: 700;
    width: 75%;
    padding-bottom: 0px;
    margin-top: 10px;
    margin-bottom: 13px;
    color: var(--color-dark);
}
.descxsb4311{
  font-weight: 300;
    width: 90%;
    margin: 0px;
    padding: 0px;
    font-size: 20px;
    color: var(--color-dark);
}
  .flexturn{
    display: flex;
    flex-direction:row;
    margin-top: 20px;
  }
  .disp2fr{
   
    max-width: 80%;
  }
.dsadsadsa2x3232e{
display: none;
}
  .aligncenterleft{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

  @media(max-width:1350px)
  {
    .text-section2{
      flex-direction: column;
      justify-content: center;
      position: absolute;
      right: 4%;
      width: 86%;
      z-index: 10;
    }
  }
  @media(max-width:1000px)
  {
    .dsadsadsa2x3232e{
      display: block;
    }
    .xsdsds2312312w{
      display: none;
    }
    .innerservicesection3 {
      font-size: 18px;
      font-weight: 200;
      width: 100%;
  }
  .flexturn {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}
.backmadenciservo {
  background-color: transparent;
  background-image: none;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.büybüy {
  width: 100%;
  margin-bottom: 20px;
}
    .innerservicesection2{
      line-height: 50px;
      font-size: 50px;
      font-weight: 700;
      color: var(--color-body);
      width: 100%;
    }
    .workdivx{
      width: 30%;
      height: 55%;
      position: absolute;
      left: 18%;
      top: 40%;
      background-color: transparent;
      background-image: linear-gradient(180deg, #F2295B00 0%, var(--color-primary) 100%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px;
      padding-top: 30px;
    }
    .xdweqw12121{
      display: block;
      height: 400px;
      width: 100%;
      position: relative;
    }
    .imgone121{
      position: absolute;
      top: 30px;
      width: 60%;
      height: 200px;
      left:30px;
      object-fit: cover;
      object-position: center right;
    }
    .disp2fr{
      display: grid; /* Grid düzenini aktifleştirir */
      grid-template-columns: repeat(2, 1fr); /* Her satırda 2 sütun oluşturur ve her biri eşit genişlikte olur */
      max-width: 100%;
      margin-bottom: 20px;
    }
    .imgone221{
      position: absolute;
      top: 90px;
      width: 50%;
      height: 60%;
      right:"-5%";
      object-fit: cover;
      object-position: center right;
    }
    .layout-containersec23 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: auto; /* Yüksekliği tam ekran yapar */
    }
    .layout-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80vh; /* Yüksekliği tam ekran yapar */
      margin-top: 30px;
    }

   
    .text-sectionsec23{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 20px;
      position: relative;
      background-color: transparent;
    }
    .wirraxx {

      background-color: var(--color-primary-light222);
      /* width: 20%; */
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 40%;
      bottom:0;
      color:var(--color-white);
      margin-top: -15px;
  
  }
    .text-section2 {
      display: flex; /* Flexbox kullanımı */
      flex-direction: column;
      justify-content: center;
      align-items: center; /* Yatay olarak ortalama */
      position: relative;
      width: 100%; /* Genişliği otomatik yap */
      z-index: 10;
      text-align: center;
    }
    .full-screen-video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      margin-top: 30px;
      top: 0;
      left: 0;
      border: 1px solid transparent;
      border-top-left-radius: 50px;
      border-top-right-radius: 50px;

      border-bottom-left-radius: 0;
  }
    .video-section2x3ana {
      position: absolute; /* Ana elemanı relative yapıyoruz ki overlay absolute konumlanabilsin */
      width: 100%;
      height: 90%;
      margin-top: 30px;

      border-top-right-radius: 50px;
      border-top-left-radius: 50px;

      border-bottom-left-radius: none;

    }
    .innertextsection2{
      line-height: 130px;
      font-size: 120px;
      font-weight: 500;
      width: 100%;
      letter-spacing: -5px;
      color: rgba(255,255,255.3);
    }
    .innertextsection3{
      font-size: 25px;
      font-weight: 200;
      width: 75%;
      color: rgba(255,255,255.3);

    }
    .innertextsectionmining3 {
      font-size: 20px;
      font-weight: 200;
      width: 90%;
  }
  .cardmining {
    background-image: url('../../../assets/images/Service-1.jpg'); /* Arka plan resmi URL'si */
    background-size: cover; /* Resmin tamamen kapsamasını sağlar */
    background-position: center; /* Resmi ortalar */
    background-repeat: no-repeat; /* Resmin tekrar etmesini engeller */
    width: 100%; /* Kart genişliği 400px */
    height: 400px; /* Kart yüksekliği 400px */
    padding: 20px; /* Kart içeriği için boşluk */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Hafif gölge */
    border-radius: 8px; /* Kart köşelerini yuvarla */
    box-sizing: border-box; /* Padding hesaba katılması için */
    flex: 0 1 auto; /* Kartların esnememesi ve sabit kalması için */
    overflow: hidden;
   
  }
  .cardmining2 {
    background-image: url('../../../assets/images/Service-2.jpg'); /* Arka plan resmi URL'si */
    width: 100%; /* Kart genişliği 400px */
    height: 400px; /* Kart yüksekliği 400px */
    background-color: white; /* Kart arka plan rengi */
    padding: 20px; /* Kart içeriği için boşluk */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Hafif gölge */
    border-radius: 8px; /* Kart köşelerini yuvarla */
    box-sizing: border-box; /* Padding hesaba katılması için */
    flex: 0 1 auto; /* Kartların esnememesi ve sabit kalması için */
    overflow: hidden;
  
  }
    .video-section2x3ana::before {
      content: ""; /* Boş bir içerik */
      position: absolute; /* Overlay'i tam ekran konumlandırmak için absolute */
      margin-top: 30px;

      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-top-right-radius: 50px;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 0;
      background-color: rgba(1, 39, 1, .4); /* Yeşil renk (RGB: 0, 255, 0) ve %50 opaklık */
      z-index: 1; /* Video'nun üstünde görünmesi için */
      pointer-events: none; /* Overlay'in altında bulunan elementlerle etkileşim için */
    }
  }
  @media (max-width: 768px) {
    .aligncenterleft {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      text-align: left;
    }
    .titxsb4311{
      font-size: 23px;
      font-weight: 700;
      width: 90%;
      padding-bottom: 0px;
      margin-top: 10px;
      margin-bottom: 13px;
      color: var(--color-dark);
  }
  .descxsb4311{
    font-weight: 300;
      width: 90%;
      margin: 0px;
      padding: 0px;
      font-size: 15px;
      color: var(--color-dark);
  }
    .ewewsd12133xz{
      font-size: 25px;
      font-weight: 700;
      color: var(--color-primary);
      letter-spacing: 2px;
    }
    .innertextsection2{
      line-height: 100px;
      font-size: 80px;
      font-weight: 500;
      width: 100%;
      letter-spacing: -5px;
      color: rgba(255,255,255.3);
    }
    .innerservicesection2{
      line-height: 50px;
      font-size: 35px;
      font-weight: 700;
      color: var(--color-body);
      width: 100%;
    }
    .innertextsection3{
      font-size: 20px;
      font-weight: 200;
      width: 75%;
      color: rgba(255,255,255.3);

    }
    .cardmining {
      width: 100%;
    }
  }
  @media (max-width: 590px) {
    .innertextsectionmining2 {
      line-height: 60px;
      font-size: 40px;
      font-weight: 500;
      color: var(--color-body);
  }
  .card-headermining {
    margin-top: 240px; /* Başlangıçta margin-top 150px */
    transition: all 0.3s ease; /* Hover geçiş süresi */
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-left: 10px;
    padding-right:10px;
  }
  .card-headermining2 {
    margin-top: 205px; /* Başlangıçta margin-top 150px */
    transition: all 0.3s ease; /* Hover geçiş süresi */
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-left: 10px;
    padding-right:10px;
  }
  .cardmining2:hover .card-headermining2 {
    margin-top: 10px;
    overflow: hidden;
}
.cardmining:hover .card-headermining {
  margin-top: 10px;
  overflow: hidden;

  }
  
  
}

  @media (max-width: 467px) {
    .innertextsectionmining2 {
      line-height: 60px;
      font-size: 40px;
      font-weight: 500;
      color: var(--color-body);
  }
  }
