
 @import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;800&display=swap');


.astroservicescontro {

  }
  
  .astroservicescontro img {
    width: 80%;
    height: auto;

  }

  .outline-text {
    color: var(--color-dark);
    text-shadow:
      -1px -1px 0 red,  /* Sol üst gölge */
       1px -1px 0 red,  /* Sağ üst gölge */
      -1px  1px 0 red,  /* Sol alt gölge */
       1px  1px 0 red;  /* Sağ alt gölge */
    font-size: 40px;
    font-weight: bold;
  }
  .virab {
    width: 70%;
    height: 450px;
    object-fit: cover;
    object-position: top -20px left 0px; /* Pozisyonu burada ayarlayın */
  }
  .rirab {
    width: 40%;
    height: 450px;
    object-fit: cover;
    object-position: top 50px left 0px; /* Pozisyonu burada ayarlayın */
  }
  .sirab {
    width: 65%;
    height: 450px;
    object-fit: cover;
    object-position: top 35px left 0px; /* Pozisyonu burada ayarlayın */
  }
  .wirab {
    width: 65%;
    height: 450px;
    opacity: 1;
    object-fit: cover;
    object-position: top -180px left 200px; /* Pozisyonu burada ayarlayın */
  }
  .textrab{
    position: absolute;
    top: 150px;
    opacity: 0.5;
    font-size:95px;
    z-index: -2;
    left: 30%;
  }
  .half-width-inputs input {
    width: 50%; /* Genişliği yarıya indir */
    float: left; /* Yan yana dizilmesini sağla */
    box-sizing: border-box; /* Padding ve border dahil olacak şekilde genişlik hesapla */
    margin-right: 10px; /* Sağa doğru biraz boşluk ekle */
  }
  
  .half-width-inputs input:last-child {
    margin-right: 0; /* Son eleman için sağ boşluğu kaldır */
  }
  
  /* Temizleme (clearfix) */
  .half-width-inputs::after {
    content: "";
    display: table;
    clear: both;
  }
  .searab{
    position: absolute;
    top: 150px;
    opacity: 0.5; 
    font-size:95px;
    z-index: -2;
    left: 22%;
  }
  .rearab{
    position: absolute;
    top: 150px;
    opacity: 0.5; 
    font-size:95px;
    z-index: -2;
    left:15%;
  }
  
  .wiarab{
    position: absolute;
    top: 150px;
    opacity: 0.5; 
    font-size:95px;
    z-index: -2;
    left:18%;
  }

  .astroservicescontro .text-content {
 
  }
  
  .btnnn {
    /* Düğme stiliniz */
  }
  
  @media (max-width: 576px) {
    .virab{
      width: 100%;
    }
.sirab{
  width: 1000%;
}
.rirab {
  width: 100%;

}

.wirab {
  width: 100%;
  object-position: top -30px left 0px;
}
.wiarab {
  left: 0;
  top: 310px;
}
   

  }