
 @import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;800&display=swap');

  
  @import "src/assets/scss/default/_variables.scss";
  .astralcontactmain {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 2;
    padding: 5px;
}

.newbigdiv{
    padding-top: 25px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    width: 900px;
}
.lefttex{

  text-align: left;

}

.contact-formm .form-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px; /* Sütunlar arası boşluk */
    margin-bottom: 20px; /* Satırlar arası boşluk */
  }
  .contact-formm {
    margin: 20px; /* Satırlar arası boşluk */

  }
  
  .contact-formm .form-row > input,
  .contact-formm textarea,
  .contact-formm .form-row > select{
    padding: 10px;
    border: 2px solid rgba(255,255,255,0.2);
    border-radius: 0px;



  }


  .contact-formm .form-row > select {
    background-color: transparent;
    color: rgba(255, 255, 255, 0.5);

  }
  .contact-formm .form-row > select > option
  {
    background-color: black;

  }
  .contact-formm input::placeholder,
  .contact-formm textarea::placeholder {
    /* Yer tutucu opaklığını ayarla */
    color: rgba(255, 255, 255, 0.5); /* Daha düşük opaklık için değeri azaltın */
    opacity: 1; /* Bazı tarayıcılarda gerekli */
  }
  
  .contact-formm input:focus::placeholder,
  .contact-formm textarea:focus::placeholder {
    /* Input focus alınca yer tutucu rengini değiştir */
    opacity: 1; /* Opaklık tam */
  }
  .service-container {
    position: relative;
  }
  
  .service-background {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1; /* Başlığın arkasında kalsın */
    /* Görselin boyutlarına göre width ve height ayarlayın */
    width: 60%;
    height: auto;
    opacity: 0.7;
  }
  
  .zzz {
    z-index: 2; /* Başlık, görselin önünde olsun */
   
  }
  
  .contact-formm textarea {
    width: 100%;
    padding: 10px;
    border: 2px solid rgba(255,255,255,0.2);
    resize: vertical; /* Kullanıcının yüksekliğini değiştirmesine izin ver */
    color: rgba(255,255,255,0.5); /* Tam beyaz */
    border-radius: 0px;
    margin-bottom: 20px;
    height: 150px;
  }

  .contact-formm input {
    border: 2px solid rgba(255,255,255,0.9);

  }
  
  
  @media (max-width: 899px) {
    .contact-formm .form-row {
      display: grid;
      grid-template-columns: 1fr; /* İki sütun oluştur */
      grid-gap: 15px; /* Sütunlar arası boşluk */
      margin-bottom: 20px; /* Satırlar arası boşluk */
    }
  .newbigdiv{
      padding-top: 25px;
      margin-right: auto;
      margin-left: auto;
      text-align: center;
      width: 100%;
  }
  }

  .custom-select-wrapper {
    position: relative;
    display: inline-block;
    -webkit-appearance: none; /* Chrome, Safari ve diğer WebKit tabanlı tarayıcılarda varsayılan oku gizle */
    -moz-appearance: none; /* Firefox'ta varsayılan oku gizle */
    appearance: none; /* Modern tarayıcılarda varsayılan oku gizle */
  }

  .custom-select-wrapper select {
    padding-right: 25px; /* Ok ikonu için sağda yer aç */
    -webkit-appearance: none; /* Chrome ve Safari için varsayılan oku gizle */
    -moz-appearance: none; /* Firefox için varsayılan oku gizle */
    appearance: none; /* Modern tarayıcılarda varsayılan oku gizle */
    background-color: transparent;
    border: 2px solid rgba(255,255,255,0.2);
    padding: 10px;
    color: rgba(255,255,255,0.5);
    border-radius: 0px;
  }

  .custom-select-wrapper option {
    background-color: var(--color-dark);

  }

  .custom-arrow {
    position: absolute;
    top: 50%; /* Oku dikey olarak ortala */
    right: 10px; /* Sağ kenardan 10px uzaklık */
    transform: translateY(-50%); /* Y ekseninde %50 yukarı çekerek tam ortalamak */
    pointer-events: none; /* Kullanıcı tıklamasını engelle */
    color: rgba(255,255,255,0.5);; /* Ok rengi */
    font-size: 12px;
    /* İsteğe bağlı olarak ok boyutu, font ailesi vb. ekleyebilirsiniz */
  }


  .contact-formm button{
  
     
  }
  .contact-formm button {
 
  }

  .custom-btnn {
    color: #fff;
    width: 40%;
    height: 50px;
    padding: 10px 25px;
    font-family: 'JOST', sans-serif;
    font-weight: 500;
    font-size: 23px;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    opacity: 0.9;
    vertical-align: middle;

  }
  /* 6 */
.btn-6 {
    background: #db2121;
    line-height: 40px;
    padding: 0;
    border: none;
    box-shadow: 0 0 5px #db2121;
  }
  .btn-6 span {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    vertical-align: middle;
}
.btn-6 span p {
    display: flex;
    align-items: center; /* Dikey olarak ortala */
    justify-content: center; /* Yatay olarak ortala */
    height: 100%;
    font-size: 20px; /* İstediğiniz font boyutu */
    margin: 0; /* Varsayılan 'p' etiketi margin'ini sıfırla */
    color: white;
  }
  .btn-6 span p:hover {
    color: rgba(255,255,255,0.5);

  }
  .btn-6:hover{
    background: transparent;
    color: rgba(255,255,255,0.5);
  }
  .btn-6:before,
  .btn-6:after {
    position: absolute;
    content: "";
    height: 0%;
    width: 2px;
    background: #db2121;
   box-shadow: 0 0 5px #db2121;
  }
  .btn-6:before {
    right: 0;
    top: 0;
    transition: all 500ms ease;
  }
  .btn-6:after {
    left: 0;
    bottom: 0;
    transition: all 500ms ease;
  }
  .btn-6:hover:before {
    transition: all 500ms ease;
    height: 100%;
  }
  .btn-6:hover:after {
    transition: all 500ms ease;
    height: 100%;
  }
  .btn-6 span:before,
  .btn-6 span:after {
    position: absolute;
    content: "";
    background: #db2121;
    box-shadow: 0 0 5px #db2121;
  }
  .btn-6 span:before {
    left: 0;
    top: 0;
    width: 0%;
    height: 2px;
    transition: all 500ms ease;
  }
  .btn-6 span:after {
    right: 0;
    bottom: 0;
    width: 0%;
    height: 2px;
    transition: all 500ms ease;
  }
  .btn-6 span:hover:before {
    width: 100%;
  }
  .btn-6 span:hover:after {
    width: 100%;
  }
  .arrayze{
    height: auto;
    overflow: hidden;
    margin-bottom: 50px;
  }
  .cesitli-koseler {
    position: relative;
    margin: 8px;
  }
  
  .cesitli-koseler:after {
    display: block;
    content: "";
    width: 150px;
    height: 150px;
    position: absolute;
    top: -5px;
    right: -5px;
    border-top: 3px solid red;
    border-right: 3px solid red;
  }
  
  .cesitli-koseler:before {
    display: block;
    content: "";
    width: 150px;
    height: 150px;
    position: absolute;
    bottom: 0px;
    left: -5px;
    border-bottom: 3px solid red;
    border-left: 3px solid red;
  }


  
  @media (max-width: 576px) {
    .service-background {
      position: absolute;
      top: -80%;
      left: 50%;
      z-index: -1; /* Başlığın arkasında kalsın */
      /* Görselin boyutlarına göre width ve height ayarlayın */
      width: 30%;
      height: auto;
      opacity: 0.7;
    }
    .custom-btnn {
      width: 80%;
    }
   

  }