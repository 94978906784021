@font-face { 
    font-family:elementskit;
    src:url(https://gtkit.rometheme.pro/gordon/wp-content/plugins/elementskit-lite/modules/elementskit-icon-pack/assets/fonts/elementskit.woff?y24e1e) format("woff");
    font-weight:400;
    font-style:normal;
    font-display:swap;
  } 
  .slick-prev, .slick-next {
    background: none !important; /* Kırmızı kareyi kaldır */
    border: none !important;
    outline: none !important;
    color: darkgreen !important; /* Okların rengi */
    z-index: 1;
    font-size: 30px; /* Okların boyutu */
    text-indent: -9999px; /* Next ve Previous yazılarını kaldır */
  }
 
  /* Ok simgeleri için stil */
  .slick-prev:before, .slick-next:before {
    color: darkgreen !important; /* Okların rengi */
    font-size: 30px;
  }
  
  .slick-prev:hover:before, .slick-next:hover:before {
    color: green !important; /* Üzerine gelince yeşil */
  }
  
  /* Noktaların boyutunu ve rengini ayarlama */
  .slick-dots li button:before {
    color: darkgreen; /* Noktaların normal hali */
    font-size: 12px; /* Nokta boyutunu küçültme */
  }
  
  .slick-dots li.slick-active button:before {
    color: green; /* Aktif nokta */
  }

  @media all{ 
   
  
 
    .elementor-element { 
      --flex-direction: initial; 
      --flex-wrap: initial; 
      --justify-content: initial; 
      --align-items: initial; 
      --align-content: initial; 
      --gap: initial; 
      --flex-basis: initial; 
      --flex-grow: initial; 
      --flex-shrink: initial; 
      --order: initial; 
      --align-self: initial; 
      flex-basis: var(--flex-basis); 
      flex-grow: var(--flex-grow); 
      flex-shrink: var(--flex-shrink); 
      order: var(--order); 
      align-self: var(--align-self);
    } 
  
    .e-con { 
      --border-radius: 0; 
      --border-top-width: 0px; 
      --border-right-width: 0px; 
      --border-bottom-width: 0px; 
      --border-left-width: 0px; 
      --border-style: initial; 
      --border-color: initial; 
      --container-widget-width: 100%; 
      --content-width: min(100%,var(--container-max-width,1140px)); 
      --width: 100%; 
      --min-height: initial; 
      --height: auto; 
      --text-align: initial; 
      --margin-top: 0px; 
      --margin-right: 0px; 
      --margin-bottom: 0px; 
      --margin-left: 0px; 
      --padding-top: var(--container-default-padding-top,10px); 
      --padding-right: var(--container-default-padding-right,10px); 
      --padding-bottom: var(--container-default-padding-bottom,10px); 
      --padding-left: var(--container-default-padding-left,10px); 
      --position: relative; 
      --z-index: revert; 
      --overflow: visible; 
      --gap: var(--widgets-spacing,20px); 
      --overlay-mix-blend-mode: initial; 
      --overlay-opacity: 1; 
      --overlay-transition: 0.3s; 
      position: var(--position); 
      width: var(--width); 
      min-width: 0; 
      min-height: var(--min-height); 
      height: var(--height); 
      border-radius: var(--border-radius); 
      z-index: var(--z-index); 
      overflow: var(--overflow); 
      transition: background var(--background-transition,.3s),border var(--border-transition,.3s),box-shadow var(--border-transition,.3s),transform var(--e-con-transform-transition-duration,.4s); 
      margin-block-start: var(--margin-block-start); 
      margin-inline-end: var(--margin-inline-end); 
      margin-block-end: var(--margin-block-end); 
      margin-inline-start: var(--margin-inline-start); 
      padding-inline-start: var(--padding-inline-start); 
      padding-inline-end: var(--padding-inline-end); 
      --margin-block-start: var(--margin-top); 
      --margin-block-end: var(--margin-bottom); 
      --margin-inline-start: var(--margin-left); 
      --margin-inline-end: var(--margin-right); 
      --padding-inline-start: var(--padding-left); 
      --padding-inline-end: var(--padding-right); 
      --padding-block-start: var(--padding-top); 
      --padding-block-end: var(--padding-bottom); 
      --border-block-start-width: var(--border-top-width); 
      --border-block-end-width: var(--border-bottom-width); 
      --border-inline-start-width: var(--border-left-width); 
      --border-inline-end-width: var(--border-right-width);
    } 
  
    .e-con { 
      display: var(--display);
    } 
  
    .e-con-boxed { 
      text-align: initial; 
      gap: initial;
    } 
  
    .e-con { 
      --container-max-width: 1340px; 
      --container-default-padding-top: 0px; 
      --container-default-padding-right: 0px; 
      --container-default-padding-bottom: 0px; 
      --container-default-padding-left: 0px;
    } 
  
    .elementor-element { 
      --widgets-spacing: 30px 30px;
    } 
  
    .e-con.e-flex { 
      --flex-basis: auto; 
      --flex-grow: 0; 
      --flex-shrink: 1; 
      flex: var(--flex-grow) var(--flex-shrink) var(--flex-basis);
    } 
  
    .e-con-boxed.e-flex { 
      flex-direction: column; 
      flex-wrap: nowrap; 
      justify-content: normal; 
      align-items: normal; 
      align-content: normal;
    } 
  
    :is(.elementor-section-wrap, [data-elementor-id]) > .e-con  { 
      --margin-left: auto; 
      --margin-right: auto; 
      max-width: min(100%,var(--width));
    } 
  
    .elementor-element.elementor-element-8a08796  { 
      --display: flex; 
      --flex-direction: row; 
      --container-widget-width: initial; 
      --gap: 0px 70px; 
      --background-transition: 0.3s; 
      --padding-top: 7em; 
      --padding-bottom: 7em; 
      --padding-left: 0em; 
      --padding-right: 0em;
    } 
  
    *,:after,:before { 
      box-sizing: border-box;
    } 
  
    .elementor * , .elementor ::after, .elementor ::before { 
      box-sizing: border-box;
    } 
  
    .e-con:before, .e-con > .elementor-background-slideshow::before, .e-con > .elementor-motion-effects-container > .elementor-motion-effects-layer::before, :is(.e-con, .e-con > .e-con-inner) > .elementor-background-video-container::before { 
      content: var(--background-overlay); 
      display: block; 
      position: absolute; 
      mix-blend-mode: var(--overlay-mix-blend-mode); 
      opacity: var(--overlay-opacity); 
      transition: var(--overlay-transition,.3s); 
      border-radius: var(--border-radius); 
      border-style: var(--border-style); 
      border-color: var(--border-color); 
      border-block-start-width: var(--border-block-start-width); 
      border-inline-end-width: var(--border-inline-end-width); 
      border-block-end-width: var(--border-block-end-width); 
      border-inline-start-width: var(--border-inline-start-width); 
      top: calc(0px - var(--border-top-width)); 
      left: calc(0px - var(--border-left-width)); 
      width: max(100% + var(--border-left-width) + var(--border-right-width),100%); 
      height: max(100% + var(--border-top-width) + var(--border-bottom-width),100%);
    } 
  
    .e-con:before { 
      transition: background var(--overlay-transition,.3s),border-radius var(--border-transition,.3s),opacity var(--overlay-transition,.3s);
    } 
  
    .elementor *, .elementor ::after, .elementor ::before { 
      box-sizing: border-box;
    } 
  
    .e-con > .e-con-inner  { 
      text-align: var(--text-align); 
      padding-block-start: var(--padding-block-start); 
      padding-block-end: var(--padding-block-end);
    } 
  
    .e-con > .e-con-inner  { 
      display: var(--display);
    } 
  
    .e-con > .e-con-inner  { 
      gap: var(--gap); 
      width: 100%; 
      max-width: var(--content-width); 
      margin: 0 auto; 
      padding-inline-start: 0; 
      padding-inline-end: 0; 
      height: 100%;
    } 
  


    .elementor-element:where(.e-con-full, .elementor-widget) { 
      flex-direction: var(--flex-direction); 
      flex-wrap: var(--flex-wrap); 
      justify-content: var(--justify-content); 
      align-items: var(--align-items); 
      align-content: var(--align-content); 
      gap: var(--gap);
    } 
  
    .e-con-full { 
      text-align: var(--text-align); 
      padding-block-start: var(--padding-block-start); 
      padding-block-end: var(--padding-block-end);
    } 
  

  
    .elementor-element.elementor-element-c9dd9ce  { 
      --display: flex; 
      --flex-direction: column; 
      --container-widget-width: 100%; 
      --justify-content: center; 
      --gap: 50px 0px; 
      --background-transition: 0.3s;
    } 
  }     
  
  @media (min-width: 768px){ 
    .elementor-element.elementor-element-c9dd9ce  { 
      --width: 100%;
    } 
  }     
  
  @media all{ 
    .e-con::before, .e-con > .elementor-background-slideshow::before, .e-con > .elementor-motion-effects-container > .elementor-motion-effects-layer::before, :is(.e-con, .e-con > .e-con-inner) > .elementor-background-video-container::before { 
      content: var(--background-overlay); 
      display: block; 
      position: absolute; 
      mix-blend-mode: var(--overlay-mix-blend-mode); 
      opacity: var(--overlay-opacity); 
      transition: var(--overlay-transition,.3s); 
      border-radius: var(--border-radius); 
      border-style: var(--border-style); 
      border-color: var(--border-color); 
      border-block-start-width: var(--border-block-start-width); 
      border-inline-end-width: var(--border-inline-end-width); 
      border-block-end-width: var(--border-block-end-width); 
      border-inline-start-width: var(--border-inline-start-width); 
      top: calc(0px - var(--border-top-width)); 
      left: calc(0px - var(--border-left-width)); 
      width: max(100% + var(--border-left-width) + var(--border-right-width),100%); 
      height: max(100% + var(--border-top-width) + var(--border-bottom-width),100%);
    } 
  
    .elementor-element.elementor-element-bbe089f  { 
      --display: flex; 
      --flex-direction: column; 
      --container-widget-width: 100%; 
      --background-transition: 0.3s;
    } 
  }     
  
  @media (min-width: 768px){ 
    .elementor-element.elementor-element-bbe089f  { 
      --width: 100%;
    } 
  }     
  
  @media all{ 
    .elementor-element.elementor-element-5ba1fde  { 
      --display: flex; 
      --flex-direction: row; 
      --container-widget-width: initial; 
      --gap: 30px 0px; 
      --flex-wrap: wrap; 
      --background-transition: 0.3s;
    } 
  
    .elementor-widget { 
      position: relative;
    } 
  
    .e-con .elementor-widget  { 
      min-width: 0;
    } 
  
    .e-con .elementor-widget-google_maps  { 
      width: 100%;
    } 
  
    .e-con .elementor-widget.elementor-widget  { 
      margin-block-end: 0;
    } 
  
    .elementor.elementor .e-con > .elementor-widget  { 
      max-width: 100%;
    } 
  
    .elementor-widget:not(:last-child) { 
      margin-bottom: 20px;
    } 
  
    .elementor-widget:not(:last-child) { 
      margin-block-end: 30px;
    } 
  
    .animated { 
      animation-duration: 1.25s;
    } 
  
    .fadeInUp { 
      animation-name: fadeInUp;
    } 
  
    .animated.animated-slow { 
      animation-duration: 2s;
    } 
  
    .elementor-element.elementor-element-f0d4dfe  { 
      z-index: 1;
    } 
  
    .elementor-widget:not(:last-child).elementor-widget__width-initial { 
      margin-bottom: 0;
    } 
  
    .elementor-element.elementor-element-3627c95  { 
      --icon-box-icon-margin: 20px; 
      width: var( --container-widget-width, 50% ); 
      max-width: 50%; 
      --container-widget-width: 50%; 
    } 
  
    .elementor-element.elementor-element-1d199dd  { 
      --icon-box-icon-margin: 20px; 
      width: var( --container-widget-width, 50% ); 
      max-width: 50%; 
      --container-widget-width: 50%; 
    } 
  
    .elementor-element.elementor-element-bc08226  { 
      --icon-box-icon-margin: 20px; 
      width: var( --container-widget-width, 50% ); 
      max-width: 50%; 
      --container-widget-width: 50%; 
    } 
  
    .elementor-element.elementor-element-0b78109  { 
      --icon-box-icon-margin: 20px; 
      width: var( --container-widget-width, 50% ); 
      max-width: 50%; 
      --container-widget-width: 50%; 
    } 
  
    .elementor-element .elementor-widget-container  { 
      transition: background .3s,border .3s,border-radius .3s,box-shadow .3s,transform var(--e-transform-transition-duration,.4s);
    } 
  
    .elementor-widget-google_maps .elementor-widget-container  { 
      overflow: hidden;
    } 
  
    .e-con > .elementor-widget > .elementor-widget-container  { 
      height: 100%;
    } 
  
    .elementor-widget-google_maps .elementor-custom-embed  { 
      line-height: 0;
    } 
  
    .elementor-heading-title { 
      padding: 0; 
      margin: 0; 
      line-height: 1;
    } 
  

  
  

    .elementor-widget-icon-box .elementor-icon-box-wrapper  { 
      display: block; 
      text-align: center;
    } 
  }     
  
  @media (min-width: 768px){ 
    .elementor-widget-icon-box.elementor-vertical-align-middle .elementor-icon-box-wrapper  { 
      align-items: center;
    } 
  }     
  
  @media all{ 
    .elementor-widget-icon-box.elementor-position-left .elementor-icon-box-wrapper  { 
      display: flex;
    } 
  
    .elementor-widget-icon-box.elementor-position-left .elementor-icon-box-wrapper  { 
      text-align: start; 
      flex-direction: row;
    } 
  
   
  
 
  
    .elementor-element.elementor-element-f1fbfda iframe  { 
      height: 475px; 
      filter: brightness( 75% ) contrast( 100% ) saturate( 0% ) blur( 0px ) hue-rotate( 0deg ); 
      transition-duration: 0.7s;
    } 
  
    .require-login { 
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; 
      background-color: white; 
      border-radius: 5px; 
      border-left: solid 10px #ff0000; 
      padding-top: 0.5rem; 
      padding-bottom: 0.5rem; 
      display: none; 
      position: absolute; 
      width: 100%; 
      z-index: 10; 
      -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) forwards; 
      animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) forwards;
    } 
  
    .success-submit { 
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; 
      background-color: white; 
      border-radius: 5px; 
      border-left: solid 10px #4CAF50; 
      padding-top: 0.5rem; 
      padding-bottom: 0.5rem; 
      display: none; 
      position: absolute; 
      width: 100%; 
      z-index: 10; 
      -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) forwards; 
      animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) forwards;
    } 
  
    .elementor-widget-icon-box .elementor-icon-box-icon  { 
      margin-bottom: var(--icon-box-icon-margin,15px); 
      margin-right: auto; 
      margin-left: auto;
    } 
  
    .elementor-widget-icon-box.elementor-position-left .elementor-icon-box-icon  { 
      display: inline-flex; 
      flex: 0 0 auto;
    } 
  
    .elementor-widget-icon-box.elementor-position-left .elementor-icon-box-icon  { 
      margin-right: var(--icon-box-icon-margin,15px); 
      margin-left: 0; 
      margin-bottom: unset;
    } 
  
    .elementor-widget-icon-box .elementor-icon-box-content  { 
      flex-grow: 1;
    } 
  
    .require-msg-body { 
      display: flex; 
      align-items: center; 
      gap: 1rem; 
      height: inherit; 
      padding-inline-start: 1.5rem;
    } 
  
    .success-body { 
      display: flex; 
      align-items: center; 
      gap: 1rem; 
      height: inherit; 
      padding-inline-start: 1.5rem;
    } 
  }     
  
  .elementor-559 .elementor-element.elementor-element-1ac04ec  { 
      --display: flex; 
      --flex-direction: column; 
      --container-widget-width: 100%; 
      --gap: 20px 0px; 
      --background-transition: 0.3s;
  } 
  
  @media all{ 
    .elementor-559 .elementor-element.elementor-element-1ac04ec  { 
      --display: flex; 
      --flex-direction: column; 
      --container-widget-width: 100%; 
      --gap: 20px 0px; 
      --background-transition: 0.3s;
    } 
  
    .elementor-icon { 
      display: inline-block; 
      line-height: 1; 
      transition: all .3s; 
      color: #69727d; 
      font-size: 50px; 
      text-align: center;
    } 
  
    .elementor-element.elementor-element-3627c95 .elementor-icon  { 
      font-size: 50px;
    } 
  
    .elementor-element.elementor-element-3627c95.elementor-view-default .elementor-icon  { 
      fill: var( --e-global-color-6578e3d ); 
      color: var( --e-global-color-6578e3d ); 
      border-color: var( --e-global-color-6578e3d );
    } 
  
    .elementor-icon:hover { 
      color: #69727d;
    } 
  


  
    .elementor-element.elementor-element-3627c95 .elementor-icon-box-title  { 
      margin-bottom: 10px;
    } 
  

  
    .elementor-widget-icon-box .elementor-icon-box-description  { 
      margin: 0;
    } 
  
    
  
    .elementor-element.elementor-element-1d199dd .elementor-icon  { 
      font-size: 50px;
    } 
  
    .elementor-element.elementor-element-1d199dd.elementor-view-default .elementor-icon  { 
      fill: var( --e-global-color-6578e3d ); 
      color: var( --e-global-color-6578e3d ); 
      border-color: var( --e-global-color-6578e3d );
    } 
  
    .elementor-element.elementor-element-1d199dd .elementor-icon-box-title  { 
      margin-bottom: 10px;
    } 
  
   
    .elementor-element.elementor-element-bc08226 .elementor-icon  { 
      font-size: 50px;
    } 
  
    .elementor-element.elementor-element-bc08226.elementor-view-default .elementor-icon  { 
      fill: var( --e-global-color-6578e3d ); 
      color: var( --e-global-color-6578e3d ); 
      border-color: var( --e-global-color-6578e3d );
    } 
  
    .elementor-element.elementor-element-bc08226 .elementor-icon-box-title  { 
      margin-bottom: 10px;
    } 
  
   
    .elementor-element.elementor-element-0b78109 .elementor-icon  { 
      font-size: 50px;
    } 
  
    .elementor-element.elementor-element-0b78109.elementor-view-default .elementor-icon  { 
      fill: var( --e-global-color-6578e3d ); 
      color: var( --e-global-color-6578e3d ); 
      border-color: var( --e-global-color-6578e3d );
    } 
  
    .elementor-element.elementor-element-0b78109 .elementor-icon-box-title  { 
      margin-bottom: 10px;
    } 
  

  
    .icon { 
      font-family: elementskit!important; 
      speak: none; 
      font-style: normal; 
      font-weight: 400; 
      font-variant: normal; 
      text-transform: none; 
      line-height: 1; 
      -webkit-font-smoothing: antialiased; 
      -moz-osx-font-smoothing: grayscale;
    } 
  
    .elementor-icon i  { 
      width: 1em; 
      height: 1em; 
      position: relative; 
      display: block;
    } 
  
    .ekit-wid-con .fasicon,.icon , .ekit-wid-con .icon::before,.fasicon,.icon,.icon:before { 
      font-family: elementskit!important; 
      speak: none; 
      font-style: normal; 
      font-weight: 400; 
      font-variant: normal; 
      text-transform: none; 
      line-height: 1; 
      -webkit-font-smoothing: antialiased; 
      -moz-osx-font-smoothing: grayscale;
    } 
  
    .elementor-icon i::before, .elementor-icon svg::before { 
      position: absolute; 
      left: 50%; 
      transform: translateX(-50%);
    } 
  
    .ekit-wid-con .fasicon.icon-phone-call2::before, .ekit-wid-con .icon.icon-phone-call2::before,.icon.icon-phone-call2:before { 
      content: "\ea54";
    } 
  
    .ekit-wid-con .fasicon, .ekit-wid-con .icon, .ekit-wid-con .icon::before, .fasicon, .icon, .icon::before { 
      speak: none; 
      font-style: normal; 
      font-weight: 400; 
      font-variant: normal; 
      text-transform: none; 
      line-height: 1; 
      -webkit-font-smoothing: antialiased; 
      -moz-osx-font-smoothing: grayscale;
    } 
  
    .ekit-wid-con .fasicon.icon-email::before, .ekit-wid-con .icon.icon-email::before,.icon.icon-email:before { 
      content: "\e998";
    } 
  
    .ekit-wid-con .fasicon.icon-globe::before, .ekit-wid-con .icon.icon-globe::before,.icon.icon-globe:before { 
      content: "\eacf";
    } 
  
    .ekit-wid-con .fasicon.icon-placeholder2::before, .ekit-wid-con .icon.icon-placeholder2::before,.icon.icon-placeholder2:before { 
      content: "\ea59";
    } 
  
 
  
    .require-msg-body h5  { 
      font-weight: bold !important; 
      margin-bottom: 0px !important;
    } 
  
    
  
    .close-msg { 
      background-color: transparent !important; 
      color: gray !important; 
      border: none !important; 
      padding-inline-end: 20px !important;
    } 
  
    [type="button"] { 
      width: auto; 
      -webkit-appearance: button;
    } 
  
    [type="button"] { 
      display: inline-block; 
      font-weight: 400; 
      color: rgb(204, 51, 51); 
      text-align: center; 
      white-space: nowrap; 
      -webkit-user-select: none; 
      -moz-user-select: none; 
      user-select: none; 
      background-color: transparent; 
      border: 1px solid rgb(204, 51, 51); 
      padding: .5rem 1rem; 
      font-size: 1rem; 
      border-radius: 3px; 
      transition: all .3s;
    } 
  
    .elementor a { 
      box-shadow: none; 
      text-decoration: none;
    } 
  
    .elementor-kit-3 a { 
      color: var( --e-global-color-6578e3d );
    } 
  
    [type="button"]:not(:disabled) { 
      cursor: pointer;
    } 
  
    a:not([href]):not([tabindex]) { 
      color: inherit; 
      text-decoration: none;
    } 
  
    a:active,a:hover { 
      color: var(--color-primary);
    } 
  
    .close-msg:hover { 
      color: black !important; 
      background-color: transparent !important;
    } 
  
    [type="button"]:hover { 
      color: #fff; 
      background-color: rgb(204, 51, 51); 
      text-decoration: none;
    } 
  
    .elementor-kit-3 a:hover { 
      color: var( --e-global-color-accent );
    } 
  
    a:not([href]):not([tabindex]),a:not([href]):not([tabindex]):hover { 
      color: inherit; 
      text-decoration: none;
    } 
  
    .success-body h5  { 
      font-weight: bold !important; 
      margin-bottom: 0px !important;
    } 
  
    .rform-button-container { 
      display: flex; 
      width: 100%;
    } 
  
    .rform-control { 
      display: flex;
    } 
  
    .rform-error { 
      display: none;
    } 
  }     
  

  
  @media all{ 
    .elementor-559 .elementor-element.elementor-element-cb8d63c .rform-error  { 
      text-align: left; 
      color: var( --e-global-color-4d86efb ); 
      font-size: var( --e-global-typography-text-font-size ); 
      font-weight: var( --e-global-typography-text-font-weight ); 
      line-height: var( --e-global-typography-text-line-height );
    } 
  
    .rform-help-text { 
      font-size: 13px; 
      font-weight: 400; 
      line-height: 17px; 
      color: #96989F;
    } 
  }     
  
  .elementor-559 .elementor-element.elementor-element-6b34617 .rform-error  { 
      text-align: left; 
      color: var( --e-global-color-4d86efb ); 
      font-size: var( --e-global-typography-text-font-size ); 
      font-weight: var( --e-global-typography-text-font-weight ); 
      line-height: var( --e-global-typography-text-line-height );
  } 
  
  @media all{ 
    .elementor-559 .elementor-element.elementor-element-6b34617 .rform-error  { 
      text-align: left; 
      color: var( --e-global-color-4d86efb ); 
  
    } 
  }     
  
  .elementor-559 .elementor-element.elementor-element-70fc6c8 .rform-error  { 
      text-align: left; 
      color: var( --e-global-color-4d86efb ); 

      line-height: var( --e-global-typography-text-line-height );
  } 
  
  @media all{ 
    .elementor-559 .elementor-element.elementor-element-70fc6c8 .rform-error  { 
      text-align: left; 
      color: var( --e-global-color-4d86efb ); 
  
      line-height: var( --e-global-typography-text-line-height );
    } 
  
    .rform-control:has(.rform-input[aria-invalid="true"]) + .rform-error  { 
      display: block !important;
    } 
  }     
  
  .elementor-559 .elementor-element.elementor-element-6c7dc79 .rform-error  { 
      text-align: left; 
      color: var( --e-global-color-4d86efb ); 

      line-height: var( --e-global-typography-text-line-height );
  } 
  
  @media all{ 
    .elementor-559 .elementor-element.elementor-element-6c7dc79 .rform-error  { 
      text-align: left; 
 
      line-height: var( --e-global-typography-text-line-height );
    } 
  
    button { 
      font-size: 1rem; 
      line-height: 1.5; 
      margin: 0;
    } 
  
    button { 
      overflow: visible;
    } 
  
    button { 
      text-transform: none;
    } 
  
    .rform-button-submit { 
      position: relative; 
      transition: all 1s;
    } 
  
    .rform-btn-fullwidth { 
      width: 100% !important;
    } 
  
    [type="button"],button { 
      width: auto; 
      -webkit-appearance: button;
    } 
  
    [type="button"],button { 
      display: inline-block; 
      font-weight: 400; 
      color: rgb(204, 51, 51); 
      text-align: center; 
      white-space: nowrap; 
      -webkit-user-select: none; 
      -moz-user-select: none; 
      user-select: none; 
      background-color: transparent; 
      border: 1px solid rgb(204, 51, 51); 
      padding: .5rem 1rem; 
      font-size: 1rem; 
      border-radius: 3px; 
      transition: all .3s;
    } 
  
    .elementor-kit-3 button { 

      text-transform: var( --e-global-typography-accent-text-transform ); 
      line-height: var( --e-global-typography-accent-line-height ); 
      letter-spacing: var( --e-global-typography-accent-letter-spacing ); 
      color: var( --e-global-color-accent ); 
      background-color: var( --e-global-color-949830f ); 
      border-style: none; 
      border-radius: 0px 0px 0px 0px; 
      padding: 20px 20px 20px 20px;
    } 
  
    [type="button"]:not(:disabled),button:not(:disabled) { 
      cursor: pointer;
    } 
  
    [type="button"]:hover,button:hover { 
      color: #fff; 
      background-color: rgb(204, 51, 51); 
      text-decoration: none;
    } 
  
    .elementor-kit-3 button:hover { 
      color: var( --e-global-color-accent ); 
      background-color: var( --e-global-color-c04f300 ); 
      border-style: none; 
      border-radius: 0px 0px 0px 0px;
    } 
  
    input { 
      font-size: 1rem; 
      line-height: 1.5; 
      margin: 0;
    } 
  
    input { 
      overflow: visible;
    } 
  
    .rform-input { 
      background-color: rgb(219, 219, 234); 
      width: 100%; 
      border-radius: 6px;
    } 
  
    input[type="text"] { 
      width: 100%; 
      border: 1px solid #666; 
      border-radius: 3px; 
      padding: .5rem 1rem; 
      transition: all .3s;
    } 
  }     
  
  .elementor-559 .elementor-element.elementor-element-cb8d63c .rform-input  { 
      padding: 17.5px 17.5px 17.5px 17.5px; 

      line-height: var( --e-global-typography-23e912c-line-height ); 
      letter-spacing: var( --e-global-typography-23e912c-letter-spacing ); 
      word-spacing: var( --e-global-typography-23e912c-word-spacing ); 
      border-radius: 0px 0px 0px 0px; 
      color: var( --e-global-color-primary ); 
      background-color: var( --e-global-color-e1dec1a ); 
      border-style: solid; 
      border-width: 1px 1px 1px 1px; 
      border-color: var( --e-global-color-primary );
  } 
  
  @media all{ 
    .elementor-559 .elementor-element.elementor-element-cb8d63c .rform-input  { 
      padding: 17.5px 17.5px 17.5px 17.5px; 
  
      line-height: var( --e-global-typography-23e912c-line-height ); 
      letter-spacing: var( --e-global-typography-23e912c-letter-spacing ); 
      word-spacing: var( --e-global-typography-23e912c-word-spacing ); 
      border-radius: 0px 0px 0px 0px; 
      color: var( --e-global-color-primary ); 
      background-color: var( --e-global-color-e1dec1a ); 
      border-style: solid; 
      border-width: 1px 1px 1px 1px; 
      border-color: var( --e-global-color-primary );
    } 
  
    input[type="email"] { 
      width: 100%; 
      border: 1px solid #666; 
      border-radius: 3px; 
      padding: .5rem 1rem; 
      transition: all .3s;
    } 
  }     
  
  .elementor-559 .elementor-element.elementor-element-6b34617 .rform-input  { 
      padding: 17.5px 17.5px 17.5px 17.5px; 


      line-height: var( --e-global-typography-23e912c-line-height ); 
      letter-spacing: var( --e-global-typography-23e912c-letter-spacing ); 
      word-spacing: var( --e-global-typography-23e912c-word-spacing ); 
      border-radius: 0px 0px 0px 0px; 
      color: var( --e-global-color-primary ); 
      background-color: var( --e-global-color-e1dec1a ); 
      border-style: solid; 
      border-width: 1px 1px 1px 1px; 
      border-color: var( --e-global-color-primary );
  } 
  
  @media all{ 
    .elementor-559 .elementor-element.elementor-element-6b34617 .rform-input  { 
      padding: 17.5px 17.5px 17.5px 17.5px; 

      line-height: var( --e-global-typography-23e912c-line-height ); 
      letter-spacing: var( --e-global-typography-23e912c-letter-spacing ); 
      word-spacing: var( --e-global-typography-23e912c-word-spacing ); 
      border-radius: 0px 0px 0px 0px; 
      color: var( --e-global-color-primary ); 
      background-color: var( --e-global-color-e1dec1a ); 
      border-style: solid; 
      border-width: 1px 1px 1px 1px; 
      border-color: var( --e-global-color-primary );
    } 
  }     
  
  .elementor-559 .elementor-element.elementor-element-70fc6c8 .rform-input  { 
      padding: 17.5px 17.5px 17.5px 17.5px; 

      line-height: var( --e-global-typography-23e912c-line-height ); 
      letter-spacing: var( --e-global-typography-23e912c-letter-spacing ); 
      word-spacing: var( --e-global-typography-23e912c-word-spacing ); 
      border-radius: 0px 0px 0px 0px; 
      color: var( --e-global-color-primary ); 
      background-color: var( --e-global-color-e1dec1a ); 
      border-style: solid; 
      border-width: 1px 1px 1px 1px; 
      border-color: var( --e-global-color-primary );
  } 
  
  @media all{ 
    .elementor-559 .elementor-element.elementor-element-70fc6c8 .rform-input  { 
      padding: 17.5px 17.5px 17.5px 17.5px; 

      line-height: var( --e-global-typography-23e912c-line-height ); 
      letter-spacing: var( --e-global-typography-23e912c-letter-spacing ); 
      word-spacing: var( --e-global-typography-23e912c-word-spacing ); 
      border-radius: 0px 0px 0px 0px; 
      color: var( --e-global-color-primary ); 
      background-color: var( --e-global-color-e1dec1a ); 
      border-style: solid; 
      border-width: 1px 1px 1px 1px; 
      border-color: var( --e-global-color-primary );
    } 
  
    textarea { 
      font-size: 1rem; 
      line-height: 1.5; 
      margin: 0;
    } 
  
    textarea { 
      width: 100%; 
      border: 1px solid #666; 
      border-radius: 3px; 
      padding: .5rem 1rem; 
      transition: all .3s;
    } 
  
    textarea { 
      overflow: auto; 
      resize: vertical;
    } 
  }     
  
  .elementor-559 .elementor-element.elementor-element-6c7dc79 .rform-input  { 
      height: 250px; 
      padding: 17.5px 17.5px 17.5px 17.5px; 

      line-height: var( --e-global-typography-23e912c-line-height ); 
      letter-spacing: var( --e-global-typography-23e912c-letter-spacing ); 
      word-spacing: var( --e-global-typography-23e912c-word-spacing ); 
      border-radius: 0px; 
      color: var( --e-global-color-primary ); 
      background-color: var( --e-global-color-e1dec1a ); 
      border-style: solid; 
      border-width: 1px 1px 1px 1px; 
      border-color: var( --e-global-color-primary );
  } 
  
  @media all{ 
    .elementor-559 .elementor-element.elementor-element-6c7dc79 .rform-input  { 
      height: 250px; 
      padding: 17.5px 17.5px 17.5px 17.5px; 
 
      line-height: var( --e-global-typography-23e912c-line-height ); 
      letter-spacing: var( --e-global-typography-23e912c-letter-spacing ); 
      word-spacing: var( --e-global-typography-23e912c-word-spacing ); 
      border-radius: 0px; 
      color: var( --e-global-color-primary ); 
      background-color: var( --e-global-color-e1dec1a ); 
      border-style: solid; 
      border-width: 1px 1px 1px 1px; 
      border-color: var( --e-global-color-primary );
    } 
  
    .loading { 
      display: none; 
      position: absolute; 
      width: 100%; 
      top: 0; 
      left: 0; 
      height: 100%; 
      justify-content: center; 
      align-items: center; 
      background-color: var(--button--color-background); 
      border-radius: var(--button--border-radius);
    } 
  
    #loading { 
      display: inline-block; 
      width: 1.5rem; 
      height: 1.5rem; 
      border: 3px solid rgba(255, 255, 255, .3); 
      border-radius: 50%; 
      border-top-color: #fff; 
      animation: spin 1s ease-in-out infinite; 
      -webkit-animation: spin 1s ease-in-out infinite;
    } 
  }     
  
  
  @keyframes fadeInUp { 
    0% {  
        opacity: 0; 
        transform: translate3d(0,100%,0); 
        opacity: 0; 
        transform: translate3d(0px, 100%, 0px); 
    }  
    100% {  
        opacity: 1; 
        transform: none; 
        opacity: 1; 
        transform: none; 
    }  
  
  } 
  @keyframes slide-bottom { 
    0% {  
        -webkit-transform: translateY(-20px); 
        transform: translateY(-20px); 
        opacity: 0; 
        transform: translateY(-20px); 
        opacity: 0; 
    }  
    100% {  
        -webkit-transform: translateY(0); 
        transform: translateY(0); 
        opacity: 1; 
        transform: translateY(0px); 
        opacity: 1; 
    }  
  
  } 
  @keyframes spin { 
    100% {  
        -webkit-transform: rotate(360deg); 
        transform: rotate(360deg); 
    }  
  
  } 
  /* These were inline style tags. Uses id+class to override almost everything */
  #style-oD5hn.style-oD5hn {  
     width: 100% ;  
  }  
  #style-Yi8Iz.style-Yi8Iz {  
     width: 100%;  
  }  

  @media screen and (max-width: 1024px) { 
    [type=button], button {
      display: inline-block;
      font-weight: 400;
      color: white;
      width: 100%;
      background-color:rgb(204, 51, 51);
      text-align: center;
      white-space: nowrap;
      -webkit-user-select: none;
      user-select: none;
      border: 1px solid rgb(204, 51, 51);
      padding: 0.5rem 1rem;
      font-size: 1rem;
      border-radius: 3px;
      transition: all 0.3s;
      margin-bottom: 30px;
  }
  .iltitle{
    color: var(--color-primary);
    font-size: 30px;
    font-weight: 500;
  }
  .elementor-element.elementor-element-8a08796 {
    --display: flex;
    --flex-direction: row;
    --container-widget-width: initial;
    --gap: 0px 70px;
    --background-transition: 0.3s;
    --padding-top: 2em;
    --padding-bottom: 2em;
    --padding-left: 0em;
    --padding-right: 0em;
}
  .XXXXX1Q222{
    padding-left: 5%;
    padding-right: 5%;

  }
  .rform-button-container { 
    display: flex; 
    width: 40%;
  } 
}

