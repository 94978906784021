.innertextsection2sectionservice{
  line-height: 110px;
  font-size: 90px;
  font-weight: 500;
  width: 80%;
  letter-spacing: -5px;
  color: var(--color-body);
}
.cax1222xaaa{
  max-width: 80%;
}
.video-section333x21 {
    flex: 6 1;
    position: relative;
}
.imgsection2x2111{
  height: 90%;
  object-fit: cover;
  width: 100%;
}
.liminour { 
    margin-block-start: 0; 
    margin-block-end: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    vertical-align: baseline; 
    background: transparent;
  } 
  .layout-containerse3232 {
    display: flex;
   height: 80vh;
  }
  .axdox{
    margin-left: 14%;
  }
  .layout-containerse32322 {
    display: flex;
   height: 80vh;
  }
  .text-sectiontit333 {
    flex: 4; /* %40 genişlik */
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    position: relative;
    background-color: transparent;
  }

.sddxadsa{
  font-size: 16px;
}
.text-sectiontitsub333 {
      display: flex; /* Flexbox kullanımı */
      flex-direction: column;
      justify-content: flex-start;
      text-align: left;
    margin-left: 10%;
      position: relative;
      width: 100%; /* Genişliği otomatik yap */
      z-index: 10;
    }


.h4minour { 
    font-size: 1.5rem;
    margin-block-start: .5rem; 
    margin-block-end: 1rem; 
    font-weight: 500; 
    line-height: 1.2; 
    color: inherit;
  } 
  .custom-cert-container {
    position: absolute;
    left: 20%;
    top: 20%;
    text-align: left;
    display: flex;
    flex-direction: column;
    max-width: 28%;
    z-index: 0;
  }
  .x23232323232
  {
   margin-left: 100px;
  }
  .container2323232323x {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 180%;

  }
/* Kitap görüntüsü için temel stiller */
.flip-book-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.demoPage {
  width: 100%; /* Sayfanın genişliğini %100 olarak ayarlar */
  height: 100%; /* Sayfanın yüksekliğini %100 olarak ayarlar */
  display: flex; /* İçerikleri ortalamak için flexbox kullanır */
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sayfa gölgesi */
  overflow: hidden; /* Sayfa taşmalarını gizler */
}

.demoPage img {
  width: 100%; /* Resmin genişliği sayfayı aşmaz */
  height: 100%; /* Resmin yüksekliği sayfayı aşmaz */
  border-radius: 5px; /* Resim köşelerini yuvarlatır */
}

.HTMLFlipBook .page {
  box-shadow: none; /* FlipBook sayfalarının varsayılan gölgesini kaldırır */
  transition: transform 0.3s ease; /* Sayfa geçiş animasyonu */
}

.HTMLFlipBook .page-inner {
  overflow: hidden; /* Sayfa taşmalarını gizler */
  background: transparent; /* Sayfa içi arka plan */
}

.HTMLFlipBook .page .page-content {
  width: 100%; /* İçeriğin tam genişlikte olmasını sağlar */
  height: 100%; /* İçeriğin tam yükseklikte olmasını sağlar */
}


  .imgminour{
    border-style: none; 
    height: auto; 
    max-width: 100%;
  }
  .ulminour{
        margin-block-start: 0; 
        margin-block-end: 0; 
        border: 0; 
        outline: 0; 
        font-size: 100%; 
        vertical-align: baseline; 
        background: transparent;

  }
  .pminour{
        margin-block-start: 0; 
        margin-block-end: .9rem;
  }

  .h3minour{
    margin-block-start: .5rem; 
    margin-block-end: 1rem; 
    font-weight: 500; 
    line-height: 1.2; 
    color: inherit;
    font-size: 1.75rem;

  }
  .elementor12121xxxx1221{ 
    width: 100%;
  } 
  @media all{ 




    .elementor-element { 
      --flex-direction: initial; 
      --flex-wrap: initial; 
      --justify-content: initial; 
      --align-items: initial; 
      --align-content: initial; 
      --gap: initial; 
      --flex-basis: initial; 
      --flex-grow: initial; 
      --flex-shrink: initial; 
      --order: initial; 
      --align-self: initial; 
      flex-basis: var(--flex-basis); 
      flex-grow: var(--flex-grow); 
      flex-shrink: var(--flex-shrink); 
      order: var(--order); 
      align-self: var(--align-self);
    } 
  
    .e-con { 
      --border-radius: 0; 
      --border-top-width: 0px; 
      --border-right-width: 0px; 
      --border-bottom-width: 0px; 
      --border-left-width: 0px; 
      --border-style: initial; 
      --border-color: initial; 
      --container-widget-width: 100%; 
      --container-widget-flex-grow: 0; 
      --content-width: min(100%,var(--container-max-width,1140px)); 
      --width: 100%; 
      --min-height: initial; 
      --height: auto; 
      --text-align: initial; 
      --margin-top: 0px; 
      --margin-right: 0px; 
      --margin-bottom: 0px; 
      --margin-left: 0px; 
      --padding-top: var(--container-default-padding-top,10px); 
      --padding-right: var(--container-default-padding-right,10px); 
      --padding-bottom: var(--container-default-padding-bottom,10px); 
      --padding-left: var(--container-default-padding-left,10px); 
      --position: relative; 
      --z-index: revert; 
      --overflow: visible; 
      --gap: var(--widgets-spacing,20px); 
      --overlay-mix-blend-mode: initial; 
      --overlay-opacity: 1; 
      --overlay-transition: 0.3s; 
      position: var(--position); 
      width: var(--width); 
      min-width: 0; 
      min-height: var(--min-height); 
      height: var(--height); 
      border-radius: var(--border-radius); 
      z-index: var(--z-index); 
      overflow: var(--overflow); 
      transition: background var(--background-transition,.3s),border var(--border-transition,.3s),box-shadow var(--border-transition,.3s),transform var(--e-con-transform-transition-duration,.4s); 
      margin-block-start: var(--margin-block-start); 
      margin-inline-end: var(--margin-inline-end); 
      margin-block-end: var(--margin-block-end); 
      margin-inline-start: var(--margin-inline-start); 
      padding-inline-start: var(--padding-inline-start); 
      padding-inline-end: var(--padding-inline-end); 
      --margin-block-start: var(--margin-top); 
      --margin-block-end: var(--margin-bottom); 
      --margin-inline-start: var(--margin-left); 
      --margin-inline-end: var(--margin-right); 
      --padding-inline-start: var(--padding-left); 
      --padding-inline-end: var(--padding-right); 
      --padding-block-start: var(--padding-top); 
      --padding-block-end: var(--padding-bottom); 
      --border-block-start-width: var(--border-top-width); 
      --border-block-end-width: var(--border-bottom-width); 
      --border-inline-start-width: var(--border-left-width); 
      --border-inline-end-width: var(--border-right-width);
    } 
  
    .e-con { 
      display: var(--display);
    } 
  
    .e-con-boxed { 
      text-align: initial; 
      gap: initial;
    } 
  
    .e-con { 
      --container-max-width: 1340px; 
      --container-default-padding-top: 0px; 
      --container-default-padding-right: 0px; 
      --container-default-padding-bottom: 0px; 
      --container-default-padding-left: 0px;
    } 
  
    .elementor-element { 
      --widgets-spacing: 30px 30px;
    } 
  
    .e-con.e-flex { 
      --flex-direction: column; 
      --flex-basis: auto; 
      --flex-grow: 0; 
      --flex-shrink: 1; 
      flex: var(--flex-grow) var(--flex-shrink) var(--flex-basis);
    } 
  
    .e-con-boxed.e-flex { 
      flex-direction: column; 
      flex-wrap: nowrap; 
      justify-content: normal; 
      align-items: normal; 
      align-content: normal;
    } 
  
    :is(.elementor-section-wrap, [data-elementor-id]) > .e-con  { 
      --margin-left: auto; 
      --margin-right: auto; 
      max-width: min(100%,var(--width));
    } 
  
    *,:after,:before { 
      box-sizing: border-box;
    } 
  
    * , .elementor ::after, .elementor ::before { 
      box-sizing: border-box;
    } 
  
    .e-con:before, .e-con > .elementor-background-slideshow::before, .e-con > .elementor-motion-effects-container > .elementor-motion-effects-layer::before, :is(.e-con, .e-con > .e-con-inner) > .elementor-background-video-container::before { 
      content: var(--background-overlay); 
      display: block; 
      position: absolute; 
      mix-blend-mode: var(--overlay-mix-blend-mode); 
      opacity: var(--overlay-opacity); 
      transition: var(--overlay-transition,.3s); 
      border-radius: var(--border-radius); 
      border-style: var(--border-style); 
      border-color: var(--border-color); 
      border-block-start-width: var(--border-block-start-width); 
      border-inline-end-width: var(--border-inline-end-width); 
      border-block-end-width: var(--border-block-end-width); 
      border-inline-start-width: var(--border-inline-start-width); 
      top: calc(0px - var(--border-top-width)); 
      left: calc(0px - var(--border-left-width)); 
      width: max(100% + var(--border-left-width) + var(--border-right-width),100%); 
      height: max(100% + var(--border-top-width) + var(--border-bottom-width),100%);
    } 
  
    .e-con:before { 
      transition: background var(--overlay-transition,.3s),border-radius var(--border-transition,.3s),opacity var(--overlay-transition,.3s);
    } 
  
    .elementor-335 .elementor-element.elementor-element-72841c1::before, .elementor-335 .elementor-element.elementor-element-72841c1 > .elementor-background-video-container::before, .elementor-335 .elementor-element.elementor-element-72841c1 > .e-con-inner > .elementor-background-video-container::before, .elementor-335 .elementor-element.elementor-element-72841c1 > .elementor-background-slideshow::before, .elementor-335 .elementor-element.elementor-element-72841c1 > .e-con-inner > .elementor-background-slideshow::before, .elementor-335 .elementor-element.elementor-element-72841c1 > .elementor-motion-effects-container > .elementor-motion-effects-layer::before { 
      --background-overlay: ''; 
      background-color: var( --e-global-color-secondary );
    } 
  
    .elementor-335 .elementor-element.elementor-element-72841c1, .elementor-335 .elementor-element.elementor-element-72841c1::before { 
      --border-transition: 0.3s;
    } 
  
    .elementor *, .elementor ::after, .elementor ::before { 
      box-sizing: border-box;
    } 
  
    .e-con > .e-con-inner  { 
      text-align: var(--text-align); 
      padding-block-start: var(--padding-block-start); 
      padding-block-end: var(--padding-block-end);
    } 
  
    .e-con > .e-con-inner  { 
      display: var(--display);
    } 
 
    
    .item {
      flex: 0 1 48%; /* 2 item yan yana olacak şekilde genişlik veriyoruz */
      margin: 1%;
    }
    .e-con > .e-con-inner  { 
      gap: var(--gap); 
      width: 100%; 
      max-width: var(--content-width); 
      margin: 0 auto; 
      padding-inline-start: 0; 
      padding-inline-end: 0; 
      height: 100%;
    } 
  
    .e-con.e-flex > .e-con-inner  { 
      flex-direction: var(--flex-direction);
    } 
  
    .e-con.e-flex > .e-con-inner  { 
      flex-wrap: var(--flex-wrap); 
      justify-content: var(--justify-content); 
      align-items: var(--align-items); 
      align-content: var(--align-content); 
      flex-basis: auto; 
      flex-grow: 1; 
      flex-shrink: 1; 
      align-self: auto;
    } 
  
    :is(.e-con, .e-con > .e-con-inner) > .elementor-background-video-container { 
      border-radius: var(--border-radius); 
      border-style: var(--border-style); 
      border-color: var(--border-color); 
      border-block-start-width: var(--border-block-start-width); 
      border-inline-end-width: var(--border-inline-end-width); 
      border-block-end-width: var(--border-block-end-width); 
      border-inline-start-width: var(--border-inline-start-width); 
      top: calc(0px - var(--border-top-width)); 
      left: calc(0px - var(--border-left-width)); 
      width: max(100% + var(--border-left-width) + var(--border-right-width),100%); 
      height: max(100% + var(--border-top-width) + var(--border-bottom-width),100%);
    } 
  
    .e-con::before, .e-con > .elementor-background-slideshow::before, .e-con > .elementor-motion-effects-container > .elementor-motion-effects-layer::before, :is(.e-con, .e-con > .e-con-inner) > .elementor-background-video-container::before { 
      content: var(--background-overlay); 
      display: block; 
      position: absolute; 
      mix-blend-mode: var(--overlay-mix-blend-mode); 
      opacity: var(--overlay-opacity); 
      transition: var(--overlay-transition,.3s); 
      border-radius: var(--border-radius); 
      border-style: var(--border-style); 
      border-color: var(--border-color); 
      border-block-start-width: var(--border-block-start-width); 
      border-inline-end-width: var(--border-inline-end-width); 
      border-block-end-width: var(--border-block-end-width); 
      border-inline-start-width: var(--border-inline-start-width); 
      top: calc(0px - var(--border-top-width)); 
      left: calc(0px - var(--border-left-width)); 
      width: max(100% + var(--border-left-width) + var(--border-right-width),100%); 
      height: max(100% + var(--border-top-width) + var(--border-bottom-width),100%);
    } 
  
    :is(.e-con, .e-con > .e-con-inner) > .elementor-background-video-container::before { 
      z-index: 1;
    } 
  
    .elementor-element.elementor-element-ad6193b  { 
      --display: flex; 
      --flex-direction: row; 
      --container-widget-width: initial; 
      --container-widget-flex-grow: 1; 
      --gap: 0px 70px; 
      --overflow: hidden; 
      --background-transition: 0.3s; 
      --padding-top: 5em; 
      --padding-bottom: 5em; 
      --padding-left: 0em; 
      --padding-right: 0em;
    } 
  
    .elementor-element:where(.e-con-full, .elementor-widget) { 
      flex-direction: var(--flex-direction); 
      flex-wrap: var(--flex-wrap); 
      justify-content: var(--justify-content); 
      align-items: var(--align-items); 
      align-content: var(--align-content); 
      gap: var(--gap);
    } 
  
    .e-con-full { 
      text-align: var(--text-align); 
      padding-block-start: var(--padding-block-start); 
      padding-block-end: var(--padding-block-end);
    } 
  
    .e-con-full.e-flex { 
      flex-direction: var(--flex-direction);
    } 
  
    .elementor-element.elementor-element-2e834d6  { 
      --display: flex; 
      --flex-direction: column; 
      --container-widget-width: 100%; 
      --container-widget-flex-grow: 0; 
      --background-transition: 0.3s;
    } 
  }     
  

  
  @media all{ 
    .animated { 
      animation-duration: 1.25s;
    } 
  
    .fadeInLeft { 
      animation-name: fadeInLeft;
    } 
  
    .animated.animated-slow { 
      animation-duration: 2s;
    } 
  
    .elementor-element.elementor-element-7215c8f  { 
      display: grid;
      grid-template-columns: repeat(2, 1fr); /* İki sütun */
      gap: 20px; /* Öğeler arasındaki boşluk */
    } 
  }     
  

  
  @media all{ 
    .elementor-widget { 
      position: relative;
    } 
  
    .elementor-widget:not(:last-child) { 
      margin-bottom: 20px;
    } 
  
    .e-con .elementor-widget  { 
      min-width: 0;
    } 
  
    .elementor-widget:not(:last-child) { 
      margin-block-end: 30px;
    } 
  
    .elementor-widget:not(:last-child).elementor-widget__width-initial { 
      margin-bottom: 0;
    } 
  
    .e-con .elementor-widget.elementor-widget  { 
      margin-block-end: 0;
    } 
  
    .elementor-element.elementor-element-aa60cd8  { 
      width: var( --container-widget-width, 46.5% ); 
      max-width: 46.5%; 
      --container-widget-width: 46.5%; 
      --container-widget-flex-grow: 0;
    } 
  
    .e-con > .elementor-widget  { 
      max-width: 100%;
    } 
  
    .elementor-element.elementor-element-1949b15  { 
      width: var( --container-widget-width, 46.5% ); 
      max-width: 46.5%; 
      --container-widget-width: 46.5%; 
      --container-widget-flex-grow: 0;
    } 
  
    .elementor-element.elementor-element-52eb17c  { 
      width: var( --container-widget-width, 46.5% ); 
      max-width: 46.5%; 
      --container-widget-width: 46.5%; 
      --container-widget-flex-grow: 0;
    } 
  
    .elementor-element.elementor-element-f89bf36  { 
      width: var( --container-widget-width, 46.5% ); 
      max-width: 46.5%; 
      --container-widget-width: 46.5%; 
      --container-widget-flex-grow: 0;
    } 
  
    .elementor-element.elementor-element-6175a59  { 
      width: var( --container-widget-width, 46.5% ); 
      max-width: 46.5%; 
      --container-widget-width: 46.5%; 
      --container-widget-flex-grow: 0;
    } 
  
    .fadeInUp { 
      animation-name: fadeInUp;
    } 
  
    .elementor-element.elementor-element-07b0432  { 
      z-index: 1;
    } 
  
    .elementor-element.elementor-element-eacfb2b  { 
      --display: flex; 
      --flex-direction: row; 
      --container-widget-width: initial; 
      --container-widget-flex-grow: 1; 
      --background-transition: 0.3s;
    } 
  
    .elementor-element.elementor-element-1d62d18  { 
      width: var( --container-widget-width, 46.5% ); 
      max-width: 46.5%; 
      --container-widget-width: 46.5%; 
      --container-widget-flex-grow: 0;
    } 
  
    .elementor-element .elementor-widget-container  { 
      transition: background .3s,border .3s,border-radius .3s,box-shadow .3s,transform var(--e-transform-transition-duration,.4s);
    } 
  
    .e-con > .elementor-widget > .elementor-widget-container  { 
      height: 100%;
    } 
  
    .elementor-element.elementor-element-b149461 > .elementor-widget-container  { 
      margin: 0px 0px 0px 0px;
    } 
  
    .elementor-element.elementor-element-4f459a1  { 
      --e-icon-list-icon-size: 15px; 
      --icon-vertical-offset: 0px; 
      width: var( --container-widget-width, 50% ); 
      max-width: 50%; 
      --container-widget-width: 50%; 
      --container-widget-flex-grow: 0;
    } 
  
    .elementor-element.elementor-element-cb4c9b0  { 
      --e-icon-list-icon-size: 15px; 
      --icon-vertical-offset: 0px; 
      width: var( --container-widget-width, 50% ); 
      max-width: 50%; 
      --container-widget-width: 50%; 
      --container-widget-flex-grow: 0;
    } 
  
    .elementor-widget-image-box .elementor-image-box-wrapper  { 
      text-align: center;
      width: 100%;
    } 
  }     
  
  @media (min-width: 768px){ 
    .elementor-widget-image-box.elementor-position-left .elementor-image-box-wrapper  { 
      display: flex;
    } 
  
    .elementor-widget-image-box.elementor-position-left .elementor-image-box-wrapper  { 
      text-align: start; 
      flex-direction: row;
    } 
  
    .elementor-widget-image-box.elementor-vertical-align-middle .elementor-image-box-wrapper  { 
      align-items: center;
    } 
  }     
  
  @media all{ 
    .elementor-heading-title { 
      padding: 0; 
      margin: 0; 
      line-height: 1;
    } 
  


  
    .elementor-widget-image-box .elementor-image-box-img  { 
      display: inline-block;
    } 
  
    .elementor-widget:not(.elementor-widget-text-editor):not(.elementor-widget-theme-post-content) figure  { 
      margin: 0;
    } 
  
    .elementor-element.elementor-element-1d62d18.elementor-position-left .elementor-image-box-img  { 
      margin-right: 25px;
    } 
  
    .elementor-element.elementor-element-1d62d18 .elementor-image-box-wrapper .elementor-image-box-img  { 
      width: 20%;
    } 
  

    .elementor-element.elementor-element-aa60cd8.elementor-position-left .elementor-image-box-img  { 
      margin-right: 25px;
    } 
  
    .elementor-element.elementor-element-aa60cd8 .elementor-image-box-wrapper .elementor-image-box-img  { 
      width: 20%;
    } 
  
    .elementor-element.elementor-element-1949b15.elementor-position-left .elementor-image-box-img  { 
      margin-right: 25px;
    } 
  
    .elementor-element.elementor-element-1949b15 .elementor-image-box-wrapper .elementor-image-box-img  { 
      width: 20%;
    } 
  
    .elementor-element.elementor-element-52eb17c.elementor-position-left .elementor-image-box-img  { 
      margin-right: 25px;
    } 
  
    .elementor-element.elementor-element-52eb17c .elementor-image-box-wrapper .elementor-image-box-img  { 
      width: 20%;
    } 
  
    .elementor-element.elementor-element-f89bf36.elementor-position-left .elementor-image-box-img  { 
      margin-right: 25px;
    } 
  
    .elementor-element.elementor-element-f89bf36 .elementor-image-box-wrapper .elementor-image-box-img  { 
      width: 20%;
    } 
  
    .elementor-element.elementor-element-6175a59.elementor-position-left .elementor-image-box-img  { 
      margin-right: 25px;
    } 
  
    .elementor-element.elementor-element-6175a59 .elementor-image-box-wrapper .elementor-image-box-img  { 
      width: 20%;
    } 
  

  
    .elementor-widget .elementor-icon-list-items  { 
      list-style-type: none; 
      margin: 0; 
      padding: 0;
    } 
  
    .elementor-element ul.elementor-icon-list-items  { 
      padding: 0;
    } 
  

  
    .elementor .imgminour { 
      height: auto; 
      max-width: 100%; 
      border: none; 
      border-radius: 0; 
      box-shadow: none;
    } 
  
    .elementor-element.elementor-element-1d62d18 .elementor-image-box-img img  { 
      transition-duration: 0.3s;
    } 
  
    .ekit-wid-con div { 
      -webkit-tap-highlight-color: transparent;
    } 
  
    .e-con > .e-con-inner > .elementor-widget > .elementor-widget-container { 
      height: 100%;
    } 
  
    .e-con.e-con > .e-con-inner > .elementor-widget { 
      max-width: 100%;
    } 
  
    .ekit-wid-con .elementskit-section-title span { 
      -webkit-transition: all .3s ease; 
      transition: all .3s ease; 
      display: inline-block; 
      font-weight: 700;
    } 
  
    .elementor-335 .elementor-element.elementor-element-c94ec7d .elementskit-section-title-wraper .elementskit-section-title > span { 
      color: var( --e-global-color-6578e3d ); 
      padding: 0px 0px 0px 0px;
    } 
  
 
  
    .elementor-335 .elementor-element.elementor-element-c94ec7d .elementskit-section-title-wraper .elementskit-section-title:hover > span { 
      color: var( --e-global-color-6578e3d );
    } 
  
    .e-con > .e-con-inner > .elementor-widget > .elementor-widget-container, .e-con > .elementor-widget > .elementor-widget-container { 
      height: 100%;
    } 
  
    .e-con.e-con > .e-con-inner > .elementor-widget, .elementor.elementor .e-con > .elementor-widget { 
      max-width: 100%;
    } 
  
    .elementor-widget-image-box .elementor-image-box-description  { 
      margin: 0;
    } 
  
    .elementor-element.elementor-element-aa60cd8 .elementor-image-box-img img  { 
      transition-duration: 0.3s;
    } 
  

  
    .elementor-element.elementor-element-aa60cd8 .elementor-image-box-title  { 
      margin-bottom: 10px;
    } 
  
    .elementor-element.elementor-element-1949b15 .elementor-image-box-img img  { 
      transition-duration: 0.3s;
    } 
  
    .elementor-element.elementor-element-1949b15 .elementor-image-box-title  { 
      margin-bottom: 10px;
    } 
  
    .elementor-element.elementor-element-52eb17c .elementor-image-box-img img  { 
      transition-duration: 0.3s;
    } 
  
    .elementor-element.elementor-element-52eb17c .elementor-image-box-title  { 
      margin-bottom: 10px;
    } 
  
    .elementor-element.elementor-element-f89bf36 .elementor-image-box-img img  { 
      transition-duration: 0.3s;
    } 
  
    .elementor-element.elementor-element-f89bf36 .elementor-image-box-title  { 
      margin-bottom: 10px;
    } 
  
    .elementor-element.elementor-element-6175a59 .elementor-image-box-img img  { 
      transition-duration: 0.3s;
    } 
  
    .elementor-element.elementor-element-6175a59 .elementor-image-box-title  { 
      margin-bottom: 10px;
    } 
  
 
    .elementor-widget .elementor-icon-list-item  { 
      margin: 0; 
      padding: 0; 
      position: relative;
    } 
  
    .elementor-widget .elementor-icon-list-item  { 
      display: flex; 
      font-size: inherit; 
      align-items: var(--icon-vertical-align,center);
    } 
  
    .elementor-element.elementor-element-4f459a1 .elementor-icon-list-items:not(.elementor-inline-items) .elementor-icon-list-item:not(:last-child)  { 
      padding-bottom: calc(15px/2);
    } 
  
    .elementor-widget .elementor-icon-list-item::after { 
      position: absolute; 
      bottom: 0; 
      width: 100%;
    } 
  
    .elementor-widget:not(.elementor-align-right) .elementor-icon-list-item::after { 
      left: 0;
    } 
  
    .elementor-widget:not(.elementor-align-left) .elementor-icon-list-item::after { 
      right: 0;
    } 
  
    .elementor-element.elementor-element-4f459a1 .elementor-icon-list-items:not(.elementor-inline-items) .elementor-icon-list-item:not(:first-child)  { 
      margin-top: calc(15px/2);
    } 
  
    .elementor-element.elementor-element-cb4c9b0 .elementor-icon-list-items:not(.elementor-inline-items) .elementor-icon-list-item:not(:last-child)  { 
      padding-bottom: calc(15px/2);
    } 
  
    .elementor-element.elementor-element-cb4c9b0 .elementor-icon-list-items:not(.elementor-inline-items) .elementor-icon-list-item:not(:first-child)  { 
      margin-top: calc(15px/2);
    } 
  
    .elementor-widget .elementor-icon-list-icon  { 
      display: flex; 
      position: relative; 
      top: var(--icon-vertical-offset,initial);
    } 
  
    .elementor-widget.elementor-widget-icon-list .elementor-icon-list-icon  { 
      text-align: var(--e-icon-list-icon-align);
    } 
  
    .elementor-element.elementor-element-4f459a1 .elementor-icon-list-icon  { 
      padding-right: 5px;
    } 
  
    .elementor-widget .elementor-icon-list-icon + .elementor-icon-list-text  { 
      align-self: center; 
      padding-inline-start: 5px;
    } 
  
    .elementor-element.elementor-element-4f459a1 .elementor-icon-list-text  { 
      color: var( --e-global-color-primary ); 
      transition: color 0.3s;
    } 
  

  
    .elementor-element.elementor-element-cb4c9b0 .elementor-icon-list-icon  { 
      padding-right: 5px;
    } 
  
    .elementor-element.elementor-element-cb4c9b0 .elementor-icon-list-text  { 
      color: var( --e-global-color-primary ); 
      transition: color 0.3s;
    } 
  

    .icon { 
      speak: none; 
      font-style: normal; 
      font-weight: 400; 
      font-variant: normal; 
      text-transform: none; 
      line-height: 1; 
      -webkit-font-smoothing: antialiased; 
      -moz-osx-font-smoothing: grayscale;
    } 
  
    .elementor-widget .elementor-icon-list-icon i  { 
      width: 1.25em; 
      font-size: var(--e-icon-list-icon-size);
    } 
  
    .elementor-element.elementor-element-4f459a1 .elementor-icon-list-icon i  { 
      color: var( --e-global-color-6578e3d ); 
      transition: color 0.3s;
    } 
  
    .ekit-wid-con .fasicon,.icon , .ekit-wid-con .icon::before,.fasicon,.icon,.icon:before { 
      speak: none; 
      font-style: normal; 
      font-weight: 400; 
      font-variant: normal; 
      text-transform: none; 
      line-height: 1; 
      -webkit-font-smoothing: antialiased; 
      -moz-osx-font-smoothing: grayscale;
    } 
  
    .ekit-wid-con .fasicon.icon-chevron-right-circle::before, .ekit-wid-con .icon.icon-chevron-right-circle::before,.icon.icon-chevron-right-circle:before { 
      content: "\e88a";
    } 
  
    .ekit-wid-con .fasicon, .ekit-wid-con .icon, .ekit-wid-con .icon::before, .fasicon, .icon, .icon::before { 
      speak: none; 
      font-style: normal; 
      font-weight: 400; 
      font-variant: normal; 
      text-transform: none; 
      line-height: 1; 
      -webkit-font-smoothing: antialiased; 
      -moz-osx-font-smoothing: grayscale;
    } 
  
    .ekit-wid-con .fasicon.icon-chevron-right-circle::before, .ekit-wid-con .icon.icon-chevron-right-circle::before, .icon.icon-chevron-right-circle::before { 
      content: "\e88a";
    } 
  
    .elementor-element.elementor-element-cb4c9b0 .elementor-icon-list-icon i  { 
      color: var( --e-global-color-6578e3d ); 
      transition: color 0.3s;
    } 
  }     
  
  
  @keyframes fadeInUp { 
    0% {  
        opacity: 0; 
        transform: translate3d(0,100%,0); 
        opacity: 0; 
        transform: translate3d(0px, 100%, 0px); 
    }  
    100% {  
        opacity: 1; 
        transform: none; 
        opacity: 1; 
        transform: none; 
    }  
  
  } 

.elementor-image-box-wrapperxx1212
{
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 48%; /* 2 item yan yana olacak şekilde genişlik veriyoruz */
  margin: 1%;
}
.image-box-wrapperxx1212imgg
{
  width: 60px;
  height: 60px;
} 
.custom-right-container {
  position: absolute;
  right: 20%;
  top: 20%;
  text-align: left;
  display: flex;
  flex-direction: column;
  max-width: 30%;
  z-index: 0;
}
.custom-span {
  color: var(--color-primary);
  font-weight: 500;
  font-size: 30px;
}
.custom-span442 {
  color: var(--color-primary);
  font-weight: 500;
  font-size: 30px;
}

.custom-h1 {
  font-size: 80px;
}
.c211x21{
  width: 100%;
  font-size: 25px;
  font-weight: 200;
  max-width: "55  %";
}
.csx2311s{
  margin-top: -25px;
  margin-bottom: 70;
}
@media screen and (max-width: 1600px) {
  .custom-h1 {
    font-size: 60px;
  }
  .innertextsection2sectionservice{
    line-height: 90px;
    font-size: 70px;
    font-weight: 500;
    width: 80%;
    letter-spacing: -5px;
    color: var(--color-body);
  }
}
@media screen and (max-width: 1200px) {
  .innertextsection2sectionservice{
    line-height: 100px;
    font-size: 90px;
    font-weight: 500;
    width: 90%;
    letter-spacing: -5px;
    color: var(--color-body);
  }

}
@media screen and (max-width: 1024px) { /* Genişlik 1024px ve altındayken */
 .video-section333x21 {
    flex: none; /* Flex özelliğini devre dışı bırak */
    position: relative;
  }
  .axdox{
    margin-left: 0;
  }
  .csx2311s{
    margin-top: -100px;
    margin-bottom: 70;
  }
  .text-sectiontitsub333 {
    display: flex; /* Flexbox kullanımı */
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
  margin-left: 5%;
    position: relative;
    width: 100%; /* Genişliği otomatik yap */
    z-index: 10;
  }
  .caxdaxwtx{
  display: flex;
  flex-direction: column-reverse;
  }
  .elementor-element.elementor-element-7215c8f {
      grid-template-columns: 1fr; /* Tek sütun */
      margin-top: 30px;
  }
  .imgsection2x2111{
    height: 80%;
    width: 100%;
    object-fit: cover;
}
  
  .ccxxx{
    padding-left: 0;
    padding-right: 0;
    height: 500px;  
    width: 100%;
  }
  .sddxadsa
  {
    font-size: 24px;
  }
  .layout-containerse3232 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto; /* Yüksekliği tam ekran yapar */
    margin-top: 30px;
  }
  .layout-containerse32322 {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    height: auto; /* Yüksekliği tam ekran yapar */
    margin-top: 30px;
  }
  .text-sectiontit333 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    position: relative;
    background-color: transparent;
    margin-top: -60px;
  }

  .e-con.e-flex > .e-con-inner {
    flex-direction: column-reverse;
    margin-bottom: 30px;
  }

  .custom-h1 {
    font-size: 60px;
  }

  .ccc1x21 {
    display: none;
  }

  .custom-cert-container {
    position: absolute;
    left: 10%;
    top: 20%;
    text-align: left;
    display: flex;
    flex-direction: column;
    max-width: 28%;
    z-index: 0;
  }
  .container2323232323x {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 30px;
  }

  .elementor-element.elementor-element-ad6193b {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    gap: 0px 70px;
    overflow: hidden;
    transition: background 0.3s;
    padding: 0;
  }

  .x23232323232 {
    padding-left: 10px !important;
  }

  /* Resim kutuları */
  .elementor-element .elementor-image-box-wrapper .elementor-image-box-img {
    width: 10%;
  }

  .elementor-widget-image-box .elementor-image-box-wrapper {
    text-align: left;
    width: 100%;
  }

}

@media screen and (max-width: 763px) { 
  .elementor-image-box-wrapperxx1212 {
    flex: 0 1 100%; /* Küçük ekranlarda item %100 genişlik alacak */
    margin: 0; /* Kenar boşluklarını da sıfırlıyoruz */
    justify-content: flex-start;
    align-items: flex-start;
  }
  .c211x21{
    width: 100%;
    font-size: 25px;
    font-weight: 200;
    max-width: "100%";
  }
  .custom-cert-container {
    position: relative;
    text-align: left;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    z-index: 0;
    left: 0;
    padding-left: 15px;
    padding-right: 10px;
  }
  .üdsdsd2321{
    display: flex;
    flex-direction: column-reverse;
  }

}
@media screen and (max-width: 520px) { 
  .elementor-image-box-wrapperxx1212 {
    flex: 0 1 100%; /* Küçük ekranlarda item %100 genişlik alacak */
    margin: 0; /* Kenar boşluklarını da sıfırlıyoruz */
    justify-content: flex-start;
    align-items: flex-start;
  }
  .innertextsection2sectionservice {
    line-height: 60px;
    font-size: 50px;
    font-weight: 600;
    width: 90%;
    letter-spacing: 0;
    color: var(--color-body);
}
.custom-span442 {
  color: var(--color-primary);
  font-weight: 500;
  font-size: 24px;
}

  .cax1222xaaa
  {
    max-width: 95%;
  }
  .custom-h1 {
    font-size: 37px;
  }
  .sddxadsa
  {
    font-size: 20px;
  }
  .custom-cert-container {
    position: relative;
    text-align: left;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    z-index: 0;
    left: 0;
    padding-left: 15px;
  }
  .üdsdsd2321{
    display: flex;
    flex-direction: column-reverse;
  }

}
