.header-containerr {
    position: relative;
  }
  
  .background-overlayy {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent; /* Yarı saydam arkaplan */
  }
  
  .centered-imagee {
    width: 100px; /* İstenilen genişlik ayarlayın */
    height: 100px; /* İstenilen yükseklik ayarlayın */
    border-radius: 50%; /* Resmi daire şeklinde yapar */
  }
  
  .top-menuu,
  .bottom-menu {
    margin-top: 10px; /* İstenilen boşluk ayarı */
  }
  
  .top-menuu ul,
  .bottom-menu ul {
    list-style: none;
    padding: 0;
    display: flex;
  }
  
  .top-menuu ul li,
  .bottom-menu ul li {
    margin: 0 10px; /* İstenilen boşluk ayarı */
  }
  
  
  