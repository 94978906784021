
 @import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;800&display=swap');

  
  @import "src/assets/scss/default/_variables.scss";

.astralblogmain {
    width: 100%;
    height: auto;
    position: relative;
    background:var(--color-darker);
    padding: 5px;
}

.newakemarg {
   
}

.rnn-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: rgba(0,0,0,0.4);
  background-clip: border-box;
  border: 1px solid rgba(255, 255, 255, 0.3);
  overflow: hidden;
  transition: border 0.3s ease;


  .inner {
      .thumbnail {
          a {
              img {
                  width: 100%;
                  filter: grayscale(100%);
                  opacity: 0.9;
                  transition: all 0.5s; /* Geçiş efekti eklemek */


              }
          }
      }

      .content {
          display: flex;
          flex-wrap: wrap;
          height: 100%;
          padding: 30px;
          padding-top: 22px;
          
          @media #{$sm-layout} {
              padding: 30px 20px;
          }
          .descriptiion {
              margin-bottom: 0;
              margin-bottom: 13px;
          }
          .title {
              font-size: 20px;
              line-height: 1.5;
              transition: all .5s;
              margin-bottom: 0;
              margin-top: 15px;
              flex-basis: 100%;
          }
      }

  }

  .thumbnaill {
    position: relative; /* Resmin bulunduğu div'i relative yaparak içindeki elemanları konumlandırabiliriz */
  }
  
  .thumbnaill img {
    width: 100%; /* Resmi div'in genişliğine göre ayarlayın */
    border-radius: 0px; /* Eğer kenar yuvarlaklığı istemiyorsanız, bu değeri sıfıra ayarlayın */
  }
  
  &.card-list-view { 
      height: 100%;
      .inner {
          display: flex;
          height: 100%;

          .thumbnail {
              flex-basis: 50%;
              a {
                  display: block;
                  height: 100%;
                  img {
                      width: auto;
                      object-fit: cover;
                      height: 100%;
                  }
              }
          }
          .content {
              flex-basis: 50%;
              .title {
                  margin-bottom: 10px;
              }
              .descriptiion {
                  margin-bottom: 13px;
                  margin-top: 12px;
              }
          }
      }
  }
}
.grayscale {
    filter: grayscale(100%) brightness(50%) contrast(150%);
}

.rn-meta-list {
  @extend %liststyle;
  font-size: 1.4rem;
  line-height: 2.2rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  li {
      margin-top: 0;
      margin-bottom: 0;
      color: var(--color-gray);
      font-size: 14px;
      

      a {
          color: var(--color-gray);
          transition: 0.5s;
      }

      &.separator {
          margin: 0 .8rem;
      }
  }
}

.rnn-meta-list {
    position: absolute; /* Mutlak pozisyon kullanarak, listeyi istediğiniz yere konumlandırabilirsiniz */
    top: 10px; /* Üstten boşluk */
    left: 10px; /* Soldan boşluk */
    list-style: none; /* Liste işaretlerini kaldır */
    margin: 0; /* Varsayılan dış boşlukları kaldır */
    padding: 0; /* Varsayılan iç boşlukları kaldır */
    background: rgba(0, 0, 0, 0.5); /* Okunabilirlik için hafif bir arka plan rengi ekleyin */
    border-radius: 5px; /* Kenar yuvarlaklığı ekleyin (isteğe bağlı) */
    padding: 5px 10px; /* İç boşluk ekleyin (isteğe bağlı) */
  }
  
  .rnn-meta-list li {
    display: inline; /* Liste öğelerini yatay hizaya getir */
  }



.rnn-card:hover {
 
    border: 1px solid rgba(255, 0, 0, 1);


    .inner {
        .thumbnail {
            a {
                img {
                    width: 100%;
                    filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(600%) contrast(1.2);
                }
            }
        }



    }

  

}
.blogcontor{
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 80px;
}
.vitangri{
    text-transform: uppercase;
    color:var(--color-body);
    font-size: 32px;
}
.imgcolor{
    filter: grayscale(1) contrast(1.5)
}
.imgcolor:hover{
    filter: none
}

.rn-meta-list:hover {
    @extend %liststyle;
    font-size: 1.4rem;
    line-height: 2.2rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    li {
        margin-top: 0;
        margin-bottom: 0;
        color: var(--color-gray);
        font-size: 14px;
        

        a {
            color: var(--color-gray);
            transition: 0.5s;
        }

        &.separator {
            margin: 0 .8rem;
        }
    }
}

@media (max-width: 576px) {
    .blogcontor{
        margin-top: 0px;
    }
    

}