
 @import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;800&display=swap');


  
  @import "src/assets/scss/default/_variables.scss";

  $red: #000;
  $white: #fff;
  

  .btn {
    color: #fff;
    cursor: pointer;
    // display: block;
    font-size:16px;
    font-weight: 400;
    line-height: 45px;
    padding: 0 0 2em;
    max-width: 180px; 
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%; 
    

    
    @media(min-width: 600px) {
        
      padding: 0 1em 2em;
    

    }
    
    &:hover { text-decoration: none; }
    
  }
  

  .btn-1 {
    background:transparent;
    font-weight: 100;
    
    svg {
      height: 45px;
      left: 0;
      position: absolute;
      top: 0; 
      width: 100%; 
    }
    
    rect {
      fill: none;
      stroke: #fff;
      stroke-width: 2;
      stroke-dasharray: 422, 0;
      transition: all 0.35s linear;
    }
  }
  
  .btn-1:hover {
    background: rgba($red, 0);
    font-weight: 900;
    letter-spacing: 1px;
    
    rect {
      stroke-width: 5;
      stroke-dasharray: 15, 310;
      stroke-dashoffset: 48;
      transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
    }
  }
  

  .basee-container {
    position: absolute;
    text-align: left;
    margin-bottom: 6px;
    z-index: 1;
    top: 22%;
    left: 15%;
  }

  .ar {
    margin: 0;
    padding: 0;
    font-weight: 300;
    font-size: 18px;
  }
  .rn-header button {
    position: relative;
    height: 50px;
    width: 100%;
    font-family: 'Jost', sans-serif;
    font-size: 18px;
    font-weight:300;
    background-color: var(--color-primary);
    background: #01506d;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #01506d, var(--color-primary));  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #01506d, var(--color-primary)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    opacity:0.9;
    color:rgba(255,255,255,0.8);
    border: none;
    cursor: pointer;
    clip-path: polygon(15% 0, 100% 0, 100% 100%, 0 100%); /* Sola eğik paralelkenar kesimi */
  
    /* Gerekirse, metin içeriğini ortalamak için aşağıdaki satırları kullanabilirsiniz */
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        font-size: 24px; /* Ikon büyüklüğünü ayarlayabilirsiniz */
        color:rgba(255,255,255,0.8);
        font-weight:300;
        margin-left:7px;
      }
     
  }
  .rn-header button:hover {
    opacity:1;
    background: linear-gradient(to left, var(--color-primary) 30%, #01506d 70%) right;
    background-size: 200%;
    transition: .5s ease-out;
  }
  
  .rn-header button:hover svg {
    font-size: 24px;
    font-weight: 600;
    margin-left: 12px;
  }
  


 
  /* Bu stil, TEKLİF AL butonunu mobil görünümde düzenlemek içindir */

  @media (max-width: 992px) {
    .rn-header button {
      display:none;
    }
  }

  .bar{
    font-weight: 800;
    font-size: 50px;    
    margin: 0;
    padding: 0;
    color: white;
    max-width: 1000px;
    word-wrap: break-word;
  }

  .btn-cont {
    color: var(--color-body);
    font-size: 17px;
    font-weight: 500;
    display: block;
    height: 80px;
    line-height: 80px;
    transition: 0.3s;

    .btnn {
        position: relative;
        padding: 6px 17px;
    -webkit-font-smoothing: antialiased;
      
      &:hover {
        border: none;
        color: var(--color-body);
   
    
      }
    }
    
    .line-1 {
      content: "";
      display: block;
      position: absolute;
      width: 1px;
      background-color: white;
      left: 0;
      bottom: 0;
    }
    .line-2 {
      content: "";
      display: block;
      position: absolute;
      height: 1px;
      background-color: white;
      left: 0;
      top: 0;
    }
    .line-3 {
      content: "";
      display: block;
      position: absolute;
      width: 1px;
      background-color: white;
      right: 0;
      top: 0;
    }
    .line-4 {
      content: "";
      display: block;
      position: absolute;
      height: 1px;
      background-color: white;
      right: 0;
      bottom: 0;
    }
  }
  
  @keyframes move1 {
    0% {
      height: 100%; 
      bottom: 0;
    }
    54% {
      height: 0; 
      bottom: 100%;
    }
    55% {
      height: 0; 
      bottom: 0;
    }
    100% {
      height: 100%; 
      bottom: 0;
    }
  }
  
  @keyframes move2 {
    0% {
      width: 0;
      left: 0;
    }
    50% {
      width: 100%; 
      left: 0;
    }
    100% {
      width: 0; 
      left: 100%;
    }
  }
  
  @keyframes move3 {
    0% {
      height: 100%; 
      top: 0;
    }
    54% {
      height: 0; 
      top: 100%;
    }
    55% {
      height: 0; 
      top: 0;
    }
    100% {
      height: 100%; 
      top: 0;
    }
  }
  
  @keyframes move4 {
    0% {
      width: 0; 
      right: 0;
    }
    55% {
      width: 100%; 
      right: 0;
    }
    100% {
      width: 0; 
      right: 100%;
    }
  }